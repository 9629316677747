import React from 'react';
import Container from './layout/container';
import Button from './ui/button';
import hostConfig from '../config/host';
import './header.scss'

const Header = () => {
  return (
    <header className="header">
      <Container>
        <div className="header__content">
          <a className="header__logo-link" href={hostConfig.mainPageURL}>
            <img className="header__logo" src={require('../images/logo.svg').default} alt="Logo" />
          </a>
          <h1 className="header__title">Heizsystemberater</h1>
          <div className="header__back-container">
            <Button href={hostConfig.mainPageURL} className="header__back-button" size="lg" color="primary">
              Zurück zur Webseite
            </Button>
          </div>
        </div>
      </Container>
    </header>
  );
};

export default Header;
