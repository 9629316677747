import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { fontSizes, scale } from '../styles';

const styles = StyleSheet.create({
  stepHeading: {
    display: 'block',
    fontFamily: 'Montserrat'
  },
  h1: {
    fontSize: fontSizes.LG_4,
    paddingTop: scale.LG_3,
    paddingBottom: scale.SM_1
  },
  h2: {
    fontSize: fontSizes.LG_3,
    // fontWeight: 600,
    paddingTop: scale.LG_3,
    paddingBottom: scale.SM_1,
  },
  h3: {
    fontSize: fontSizes.LG_2,
    fontWeight: 600,
    paddingTop: scale.LG_3,
    paddingBottom: scale.SM_1,
  }

})

const Heading = ({ headingSize, heading }) => {
  return (
    <View style={[styles.stepHeading, styles[headingSize]]}>
      <Text>{heading}</Text>
    </View>
  );
};

Heading.propTypes = {
  headingSize: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4'])
};

export default Heading;
