import React from 'react';
import styles from './checkbox.module.scss';

const Checkbox = ({ id, name, label, register, onChange, disabled, hasError }) => {
  return (
    <label className={`${styles['checkbox']} ${disabled ? styles['checkbox_disabled'] : ''}`}>
      <span className={styles['checkbox__input-container']}>
        <input className={styles['checkbox__input']} type="checkbox" value={id} name={name} disabled={disabled} ref={register} onChange={onChange} />
        <span className={`${styles['checkbox__control']} ${hasError ? styles['checkbox__control_has-error'] : ''}`}>
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' aria-hidden="true" focusable="false">
            <path fill='none' stroke='currentColor' strokeWidth='3' d='M1.73 12.91l6.37 6.37L22.79 4.59'/>
          </svg>
        </span>
      </span>
      <span className={styles['checkbox__label']}>{label}</span>
    </label>
  );
};

export default Checkbox;
