export const scale = {
  LG_4: '21',
  LG_3: '17.28',
  LG_2: '14.4',
  LG_1: '12',
  NORMAL: '10',
  SM_1: '8.33',
  SM_2: '6.94'
}

export const fontSizes = {
  LG_5: '24.88pt',
  LG_4: '20.74pt',
  LG_3: '17.28pt',
  LG_2: '14.4pt',
  LG_1: '12pt',
  NORMAL: '10pt',
  SM_1: '8.33pt',
}

export const colors = {
  LIGHT_2: '#e7e7e7',
  LIGHT_1: '#b1b1b1',
  DARK_1: '#555555',
  DARK_2: '#222222'
}
