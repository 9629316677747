import { StateMachineProvider, createStore } from 'little-state-machine';
import Container from './components/layout/container';
import Generator from './components/generator';
import Header from './components/header';
import Footer from './components/footer';
import React from 'react';
import './App.scss';

createStore({
  formStateBuilding: {}
});

function App() {
  return (
    <div className="App">
      <StateMachineProvider>
        <Header/>
        <div className="App__content">
          <Container>
            <Generator/>
          </Container>
        </div>
        <Footer/>
      </StateMachineProvider>
    </div>
  );
}


export default App;
