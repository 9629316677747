import React from 'react';
import Heading from './heading';
import { scale } from '../styles';
import { StyleSheet, View } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  section: {
    marginTop: scale.LG_2
  }
})

const Section = ({ heading, children, breakPage }) => {
  return (
    <View style={styles.section} break={breakPage}>
      <Heading headingSize="h2" heading={heading} />
        {children}
    </View>
  );
};

export default Section;
