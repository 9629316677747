const setValidators = (validators) => {
  let composedValidators = {};
  validators.forEach(validator => {
    Object.assign(composedValidators, validator)
  })
  return composedValidators;
}

const isRequired = () => {
  return {
    required: 'Diese Eingabe ist erforderlich'
  };
}

const minLength = (minLength) => {
  return {
    minLength: {
      value: minLength,
      message: `Das Feld muss mind. ${minLength} Zeichen lang sein`
    }
  }
}

const isPLZ = () => {
  return {
    pattern: {
      value: /^[0-9]{5}$/,
      message: 'Bitte geben Sie eine gültige PLZ ein'
    }
  }
}

const isYear = () => {
  return {
    pattern: {
      value: /^[0-9]{4}$/,
      message: 'Bitte geben Sie ein gültiges Jahr ein'
    }
  }
}

const isNumber = () => {
  return {
    pattern: {
      value: /^[0-9]*$/,
      message: 'Bitte geben Sie eine Nummer ein'
    }
  }
}

const isDecimalNumber = () => {
  return {
    pattern: {
      value: /^([0-9]|,)*$/,
      message: 'Bitte geben Sie eine Nummer ein'
    }
  }
}

const isEmail = () => {
  return {
    pattern: {
      value: /\S+@\S+\.\S+/,
      message: 'Bitte geben Sie eine gültige E-Mail Adresse ein'
    }
  }
}

const hasPermittedFileValues = (fileTypes, fileSize) => {
  return {
    validate: value => {
      let hasFileTypeError = false;
      let accumulatedFileSize = 0;

      [...value].forEach(file => {
        if (!fileTypes.includes(file.type)) {
          hasFileTypeError = true;
        }
        accumulatedFileSize += file.size;
      })

      if (accumulatedFileSize > fileSize) return 'Die Datei(en) überschreiten die max. erlaubte Größe';
      if (hasFileTypeError) return 'Mindestens eine Datei hat ein ungültigen Dateityp';
      return true
    }
  }
}

const fileTypes = {
  PNG: 'image/png',
  JPEG: 'image/jpeg',
  PDF: 'application/pdf',
  PLAIN: 'text/plain',
  DWG: [
    '',
    '.dwg',
    'application/acad',
    'application/x-acad',
    'application/autocad_dwg',
    'image/x-dwg',
    'application/dwg',
    'application/x-dwg',
    'application/x-autocad',
    'image/vnd.dwg',
    'drawing/dwg',
  ],
  DXF: [
    '',
    '.dxf',
    'image/vnd.dxf',
    'application/dxf'
  ]
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  setValidators,
  isRequired,
  minLength,
  isPLZ,
  isYear,
  isNumber,
  isDecimalNumber,
  isEmail,
  hasPermittedFileValues,
  fileTypes
}
