import React from 'react';
import { StyleSheet, View } from '@react-pdf/renderer';
import Heading from './heading';

const styles = StyleSheet.create({
  step: {
    // marginTop: scale.LG_2
  }
})

const Step = ({ heading, children }) => {
  return (
    <View style={styles.step}>
      <Heading headingSize="h3" heading={heading} />
      {children}
    </View>
  );
};

export default Step;
