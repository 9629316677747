import React from 'react';
import PropTypes from 'prop-types';
import './wizard.scss';

const Wizard = ({ steps, currentStep, onChangeStep }) => {
  const getStepClassNames = (index) => {
    let classNames = 'wizard-progress__step';
    if (currentStep > index) classNames += ' wizard-progress__step_is-past';
    if (currentStep === index) classNames += ' wizard-progress__step_is-current';
    return classNames;
  }

  return (
    <div className="wizard">
      <div className="wizard__header">
        <ul className="wizard-progress">
          {steps.length === 1 ? (
            <span className="wizard-progress__text_single-step">{steps[0].title}</span>
          ) : (
            steps.map((step, index) => (
              <li key={step.id} className={getStepClassNames(index)}>
                <div className="wizard-progress__circle-container">
                  <span className="wizard-progress__circle" />
                </div>
                <span className="wizard-progress__text">{step.title}</span>
              </li>
            )))}
        </ul>
      </div>
      {/*<div className="wizard__content">{children}</div>*/}
      {/*<div className="wizard__controls">*/}
      {/*  <Button color="primary" size="lg" onClick={decrementStep} disabled={isFirstStep(currentStep)}>Zurück</Button>*/}
      {/*  <Button color="primary" size="lg" onClick={incrementStep} disabled={isLastStep(currentStep)}>Weiter</Button>*/}
      {/*</div>*/}
    </div>
  );
};

Wizard.propTypes = {
  // children: PropTypes.node.isRequired,
  steps: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string
  })).isRequired,
  currentStep: PropTypes.number.isRequired,
  onChangeStep: PropTypes.func
};

export default Wizard;
