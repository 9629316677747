// Dirty
import React from 'react';
import styles from './tileCheckbox.module.scss';

const TileCheckbox = ({ id, name, label, register, onChange, disabled, hasError, icon, isHighlighted }) => {
  return (
    <label className={`${styles['checkbox']} ${disabled ? styles['checkbox_disabled'] : ''} ${isHighlighted ? styles['checkbox_highlight'] : ''}`}>
      <div className={`${styles['checkbox__icon-container']}`}>
        <img className={`${styles['checkbox__icon']}`} src={icon} alt={`${name} Icon`}/>
      </div>
      <div className={styles['checkbox__bottom']}>
        <span className={styles['checkbox__label']}>{label}</span>
        <span className={styles['checkbox__input-container']}>
          <input className={styles['checkbox__input']} type="checkbox" value={id} name={name} disabled={disabled} ref={register} onChange={onChange} />
          <span className={`${styles['checkbox__control']} ${hasError ? styles['checkbox__control_has-error'] : ''}`}>
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' aria-hidden="true" focusable="false">
              <path fill='none' stroke='currentColor' strokeWidth='3' d='M1.73 12.91l6.37 6.37L22.79 4.59'/>
            </svg>
          </span>
        </span>
      </div>
    </label>
  );
};

export default TileCheckbox;
