import React from 'react';
import PropTypes from 'prop-types';
import styles from './button.module.scss';

const Button = ({ onClick, children, color, size, className, href, hasIcon, ...rest }) => {
  if (href) {
    return (
      <a
        className={`${styles['button']} ${styles[`button_color_${color}`]} ${styles[`button_size_${size}`]} ${className ? className : ''}`}
        href={href}
        {...rest}
      >
        {children}
      </a>
    )
  }

  return (
    <button
      className={`${styles['button']} ${styles[`button_color_${color}`]} ${styles[`button_size_${size}`]} ${hasIcon ? styles[`button_has-icon`] : ''} ${className ? className : ''}`}
      type="button"
      onClick={onClick}
      {...rest}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary']),
  size: PropTypes.oneOf(['sm', 'md', 'lg'])
};

export default Button;
