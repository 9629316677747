import React, { useEffect, useState, useReducer, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useStateMachine } from "little-state-machine";
import InputGroup from './form/inputGroup';
import b, { values, steps as buildingSteps } from '../data/building';
import { values as generalValues, general, contact } from '../data/general';
import InputSelector from './form/InputSelector';
import ConditionalInputGroup from './form/conditionalInputGroup';
import Image from './ui/image';
import ImageRotation from './form/inputs/imageRotation';
import Wizard from './Wizard';
import Button from './ui/button';
import Card from './card';
import floatImgHouseDetail from '../images/icon_sidebar-house-detail.png';
import floatImgHouse0 from '../images/icon_sidebar-house_0.png';
import floatImgHouse45 from '../images/icon_sidebar-house_45.png';
import floatImgHouse90 from '../images/icon_sidebar-house_90.png';
import floatImgHouse315 from '../images/icon_sidebar-house_315.png';
import loadingSpinner from '../images/rolling_loading_spinner.gif'
import TileCheckboxGroup from './form/inputs/tileCheckboxGroup';
import { pdf } from '@react-pdf/renderer';
import PdfExport from './pdf-export/pdf-export';
import hostConfig from '../config/host';
import updateFormData from '../data/updateFormData';
import styles from './generator.module.scss';

const iconCheckmarkSuccess = require('../images/icon_checkmark_success.svg').default
const iconCrossError = require('../images/icon_cross_error.svg').default
// function updateFormData(state, payload) {
//   return {
//     ...state,
//     formStateBuilding: {
//       ...state.formStateBuilding,
//       ...payload,
//     },
//   };
// }

const stepGeneralVorhaben = {
  id: 'general_vorhaben',
  title: 'Vorhaben'
}

const stepGeneralKontakt = {
  id: 'general_kontakt',
  title: 'Kontakt'
}

const sendMailReducer = (state, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case 'send':
      return { isLoading: true, hasError: false, hasSuccess: false, errorMessage: null }
    case 'error':
      return { isLoading: false, hasError: true, hasSuccess: false, errorMessage: action.errorMessage }
    case 'success':
      return { isLoading: false, hasError: false, hasSuccess: true, errorMessage: null }
    case 'reset':
      return { isLoading: false, hasError: false, hasSuccess: false, errorMessage: null }
  }
}


const Generator = () => {
  const { actions, state } = useStateMachine({ updateFormData });
  const [steps, setSteps] = useState([stepGeneralVorhaben, ...buildingSteps, stepGeneralKontakt]);
  const [localBuildingSteps, setLocalBuildingSteps] = useState(buildingSteps);
  const [currentStep, setCurrentStep] = useState(0);
  const [roofOrientationIsFocused, setRoofOrientationIsFocused] = useState(false);
  const { register, handleSubmit, watch, errors, control, setValue } = useForm();
  const [generalIntendDisabledValues, setGeneralIntendDisabledValues] = useState([]);
  const [pdfBlob, setPdfBlob] = useState(null);
  const [sendMailState, dispatchSendMail] = useReducer(sendMailReducer, {
    isLoading: false,
    hasError: false,
    hasSuccess: false
  })

  // Watches
  const watchBuildingType = watch(b.buildingType.id);
  const watchBuildingAnnexSituation = watch(b.buildingAnnexSituation.id);
  const watchBuildingHasBasement = watch(b.buildingHasBasement.id);
  const watchBuildingBasementIsHeated = watch(b.buildingBasementIsHeated.id);
  const watchBuildingBasePlateIsIsolatedSubsequently = watch(b.buildingBasePlateIsIsolatedSubsequently.id);
  const watchBuildingBasementCeilingIsIsolatedSubsequently = watch(b.buildingBasementCeilingIsIsolatedSubsequently.id);
  const watchBuildingAtticIsHeated = watch(b.buildingAtticIsHeated.id);
  const watchBuildingCeilingFloorIsIsolatedSubsequently = watch(b.buildingCeilingFloorIsIsolatedSubsequently.id);
  const watchBuildingExteriorWallWindowsAndDoorsStatement = watch(b.buildingExteriorWallWindowsAndDoorsStatement.id);
  const watchBuildingWindowInstallationYearStatement = watch(b.buildingWindowInstallationYearStatement.id);
  const watchBuildingHeatingInstallationYearStatement = watch(b.buildingHeatingInstallationYearStatement.id);
  const watchBuildingRadiatorsInstallationYearStatement = watch(b.buildingRadiatorsInstallationYearStatement.id);
  const watchBuildingHasChimney = watch(b.buildingHasChimney.id);
  const watchBuildingUsesSolarthermics = watch(b.buildingUsesSolarthermics.id);
  const watchBuildingHasPhotovoltaics = watch(b.buildingHasPhotovoltaics.id);
  // const watchBuildingRoofShape = watch(b.buildingRoofShape.id);
  const watchBuildingRoofOrientation = watch(b.buildingRoofOrientation.id);
  const watchBuildingPhotovoltaicsInstallationYearStatement = watch(b.buildingPhotovoltaicsInstallationYearStatement.id);
  const watchGeneralIntend = watch(general.generalIntend.id);

  useEffect(() => {
    if (watchGeneralIntend?.includes(generalValues.generalIntend.UNKNOWN)) {
      if (watchGeneralIntend.length > 1) {
        setValue(general.generalIntend.id, [generalValues.generalIntend.UNKNOWN]);
      }

      const disabledValues = generalIntendDisabledValues;

      Object.values(generalValues.generalIntend).forEach(value => {
        if (value === generalValues.generalIntend.UNKNOWN) return;
        if (disabledValues.includes(value)) return;
        disabledValues.push(value);
      })
      setGeneralIntendDisabledValues([...disabledValues]);
    } else if (watchGeneralIntend && generalIntendDisabledValues.length > 0) {
      setGeneralIntendDisabledValues([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(watchGeneralIntend)])

  useEffect(() => {
    if (state?.['formStateBuilding']?.['general_vorhaben']?.['generalIntend']
      .every(intend => intend === generalValues.generalIntend.AIR_CONDITIONER)) {
      setLocalBuildingSteps(buildingSteps.filter(step => step.id === 'building_klimagereat'));
    } else if (!(
        state?.['formStateBuilding']?.['general_vorhaben']?.['generalIntend']?.includes(generalValues.generalIntend.AIR_CONDITIONER)
        || state?.['formStateBuilding']?.['general_vorhaben']?.['generalIntend']?.includes(generalValues.generalIntend.UNKNOWN)
      )
    ) {
      setLocalBuildingSteps(buildingSteps.filter(step => step.id !== 'building_klimagereat'));
    } else {
      setLocalBuildingSteps(buildingSteps);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  useEffect(() => {
    setSteps([stepGeneralVorhaben, ...localBuildingSteps, stepGeneralKontakt])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(localBuildingSteps)])

  useEffect(() => {
    setFormValues(state?.formStateBuilding?.[steps[currentStep].id]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, sendMailState])

  const setFormValues = (values) => {
    if (values) {
      const keys = Object.keys(values);
      keys.forEach(key => {
      if (key !== contact.contactFiles.id) setValue(key, values[key])
      });
    }
  }

  const isFirstStep = (step) => {
    return step <= 0;
  }

  const isLastStep = (step, length) => {
    return step >= (length - 1);
  }

  const incrementStep = () => {
    if (!isLastStep(currentStep, steps.length)) handleStepChange(currentStep + 1);
  }

  const decrementStep = () => {
    if (!isFirstStep(currentStep)) handleStepChange(currentStep - 1)
  }

  const handleStepChange = async (newStep) => {
    await handleSubmit((data) => onStepSubmit(data, newStep))();
  }

  const handleLastStepChange = async () => {
    await handleSubmit((data) => onLastStepSubmit(data))();
  }

  const onLastStepSubmit = (data) => {
    actions.updateFormData({[steps[currentStep].id]: data})
    onSubmit(data)
  }

  const onStepSubmit = (data, newStep) => {
    actions.updateFormData({[steps[currentStep].id]: data})
    setCurrentStep(newStep)
  }

  const findIndexOfBuildingStep = (id) => {
    return steps.findIndex(step => step.id === id);
  }

  const currentStepIsRoofStep = () => {
    return steps[currentStep].id === 'building_dach';
  }

  const onFocusRoofOrientation = () => {
    setRoofOrientationIsFocused(true)
  }

  const getFloatImage = useCallback(() => {
    if (roofOrientationIsFocused && currentStepIsRoofStep()) {
      switch (watchBuildingRoofOrientation) {
        case 0:
        case 180:
          return floatImgHouse0;
        case 45:
        case 225:
          return floatImgHouse45;
        case 90:
        case 270:
          return floatImgHouse90;
        case 135:
        case 315:
          return floatImgHouse315;
        default:
          return floatImgHouseDetail;
      }
    }

    return floatImgHouseDetail;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, roofOrientationIsFocused, watchBuildingRoofOrientation]);

  const onSubmit = async (data) => {
    dispatchSendMail({type: 'send'});

    const tempContactFillIn = state.formStateBuilding;
    Object.assign(tempContactFillIn, { [steps[currentStep].id]: data })

    try {
      const blob = await pdf(<PdfExport stateMachineState={{ formStateBuilding: tempContactFillIn }} />).toBlob();
      setPdfBlob(blob)
      await sendMail(data, blob);

      dispatchSendMail({type: 'success'});
    } catch (e) {
      dispatchSendMail({type: 'error', errorMessage: e.message});
    }
  }

  const sendMail = async (data, pdfBlob) => {
    const formData = new FormData();
    const email = data[contact.contactEmail.id];
    const firstName = data[contact.contactFirstName.id];
    const lastName = data[contact.contactLastName.id];
    const message = data[contact.contactMessage.id];
    const files = data[contact.contactFiles.id];
    const type = data[contact.contactCompanyPrivate.id];
    const postcode = data[contact.contactPostcode.id];
    const city = data[contact.contactCity.id];
    const street = data[contact.contactStreet.id];
    const phone = data[contact.contactPhone.id];

    [...files].forEach((file, index) => {
      formData.append(`file_${index}`, file)
    })

    formData.append('email', email);
    formData.append('first-name', firstName);
    formData.append('last-name', lastName);
    formData.append('type', contact.contactCompanyPrivate.values.find(value => value.id === type).name);
    formData.append('postcode', postcode);
    formData.append('city', city);
    formData.append('street', street);
    formData.append('phone', phone);
    formData.append('message', message);
    formData.append('md_summary_pdf', pdfBlob, `Zusammenfasssung_${lastName}-${firstName}_${city}.pdf`);

    const response = await fetch(hostConfig.mailerURL, {
      method: 'POST',
      body: formData
    })
    const responseJson = await response.json();

    if (response.status !== 200) {
      throw new Error(responseJson.message);
    }
  }


  return (
    <div className={styles['generator']}>
      {(!sendMailState.isLoading && !sendMailState.hasSuccess && !sendMailState.hasError) && (
        <React.Fragment>
          {currentStep === 0 && (
            <React.Fragment>
              <div className={styles['generator__intro-container']}>
                <span className={styles['generator__intro-text']}>
                  In wenigen Schritten zur optimalen Heizung für Ihr Gebäude.<br />
                  Für welche Heizung(en) interessieren Sie sich?
                </span>
              </div>
              <form onSubmit={handleSubmit(onStepSubmit)}>
                <TileCheckboxGroup
                  name={general.generalIntend.id}
                  register={register(general.generalIntend.validators)}
                  values={general.generalIntend.values}
                  error={errors[general.generalIntend.id]}
                  disabledValues={generalIntendDisabledValues}
                />
              </form>
            </React.Fragment>
          )}
          {currentStep > 0 && currentStep < (steps.length - 1) && (
            <div className={styles['generator__building']}>
              <div className={styles['generator__float-img-container']}>
                <img className={styles['generator__float-img']} src={getFloatImage()} alt="Gebäude"/>
              </div>
              <Card>
                <Wizard steps={localBuildingSteps} currentStep={currentStep - 1}/>
                {currentStep === findIndexOfBuildingStep('building_allgemein') && (
                  <form onSubmit={handleSubmit(onStepSubmit)}>
                    <InputGroup question={b.buildingLocation.question}>
                      <InputSelector generatorStep={b.buildingLocation} register={register} errors={errors}/>
                    </InputGroup>
                    <InputGroup question={b.buildingBuildYear.question}>
                      <InputSelector generatorStep={b.buildingBuildYear} register={register} errors={errors}/>
                    </InputGroup>
                    <InputGroup question={b.buildingResidents.question}>
                      <InputSelector generatorStep={b.buildingResidents} register={register} errors={errors}/>
                    </InputGroup>
                  </form>
                )}

                {currentStep === findIndexOfBuildingStep('building_gebaeudeAllgemein') && (
                  <form onSubmit={handleSubmit(onStepSubmit)}>
                    <InputGroup question={b.buildingType.question}>
                      <InputSelector generatorStep={b.buildingType} register={register} errors={errors}/>
                    </InputGroup>
                    {watchBuildingType === values.buildingType.MEHRFAMILIENHAUS && (
                      <ConditionalInputGroup question={b.buildingHousingUnit.question}>
                        <InputSelector generatorStep={b.buildingHousingUnit} register={register} errors={errors}/>
                      </ConditionalInputGroup>
                    )}
                    <InputGroup question={b.buildingAnnexSituation.question}>
                      <InputSelector generatorStep={b.buildingAnnexSituation} register={register} errors={errors}/>
                    </InputGroup>
                    <InputGroup question={b.buildingFullStoreys.question}>
                      <InputSelector generatorStep={b.buildingFullStoreys} register={register} errors={errors}/>
                    </InputGroup>
                    <InputGroup question={b.buildingHeatableLivingArea.question}>
                      <InputSelector generatorStep={b.buildingHeatableLivingArea} register={register} errors={errors}/>
                    </InputGroup>
                    {watchBuildingAnnexSituation === values.buildingAnnexSituation.DOPPELHAUS && (
                      <InputGroup question={b.buildingWallAdjoins.question}>
                        <div className={styles['form__row']}>
                          <Image src={require('../images/icon_waende.png').default} alt="Wände" maxWidth={230}/>
                        </div>
                        <InputSelector generatorStep={b.buildingWallAdjoins} register={register} errors={errors}/>
                      </InputGroup>
                    )}
                    <InputGroup question={b.buildingCeilingHeight.question} isOptional={b.buildingCeilingHeight.isOptional}>
                      <InputSelector generatorStep={b.buildingCeilingHeight} register={register} errors={errors}/>
                    </InputGroup>
                    <InputGroup question={b.buildingHasBasement.question}>
                      <InputSelector generatorStep={b.buildingHasBasement} register={register} errors={errors}/>
                    </InputGroup>
                    {watchBuildingHasBasement === values.buildingHasBasement.JA && (
                      <React.Fragment>
                        <ConditionalInputGroup question={b.buildingBasementIsHeated.question}>
                          <InputSelector generatorStep={b.buildingBasementIsHeated} register={register} errors={errors}/>
                        </ConditionalInputGroup>
                        {/* This question is shown below again */}
                        {watchBuildingBasementIsHeated === values.buildingBasementIsHeated.NEIN && (
                          <InputGroup question={b.buildingBasementCeilingIsIsolatedSubsequently.question}>
                            <InputSelector generatorStep={b.buildingBasementCeilingIsIsolatedSubsequently} register={register}
                                           errors={errors}/>
                            {watchBuildingBasementCeilingIsIsolatedSubsequently === values.buildingBasementCeilingIsIsolatedSubsequently.JA && (
                              <>
                                <InputSelector generatorStep={b.buildingBasementCeilingInstallationYear} register={register}
                                               errors={errors}/>
                                <InputSelector generatorStep={b.buildingBasementCeilingIsoloationStrength} register={register}
                                               errors={errors}/>
                              </>
                            )}
                          </InputGroup>
                        )}
                      </React.Fragment>
                    )}

                    <InputGroup question={b.buildingBasePlateIsIsolatedSubsequently.question}>
                      <InputSelector generatorStep={b.buildingBasePlateIsIsolatedSubsequently} register={register} errors={errors}/>
                    </InputGroup>
                    {/* Same question as above. Only show when above is not shown */}
                    {watchBuildingBasePlateIsIsolatedSubsequently === values.buildingHasBasement.JA &&
                    watchBuildingBasementIsHeated !== values.buildingBasementIsHeated.NEIN && (
                      <InputGroup question={b.buildingBasementCeilingIsIsolatedSubsequently.question}>
                        <InputSelector generatorStep={b.buildingBasementCeilingIsIsolatedSubsequently} register={register}
                                       errors={errors}/>
                        {watchBuildingBasementCeilingIsIsolatedSubsequently === values.buildingBasementCeilingIsIsolatedSubsequently.JA && (
                          <>
                            <InputSelector generatorStep={b.buildingBasementCeilingInstallationYear} register={register}
                                           errors={errors}/>
                            <InputSelector generatorStep={b.buildingBasementCeilingIsoloationStrength} register={register}
                                           errors={errors}/>
                          </>
                        )}
                      </InputGroup>
                    )}
                  </form>
                )}

                {currentStep === findIndexOfBuildingStep('building_aussenwand') && (
                  <form onSubmit={handleSubmit(onStepSubmit)}>
                    <InputGroup question={b.buildingConstructionMasonry.question}>
                      <InputSelector generatorStep={b.buildingConstructionMasonry} register={register} errors={errors}/>
                    </InputGroup>
                    <InputGroup question={b.buildingFacadeExecution.question}>
                      <InputSelector generatorStep={b.buildingFacadeExecution} register={register} errors={errors}/>
                    </InputGroup>
                    <InputGroup question={b.buildingExteriorWallIsSanitizedSubsequently.question}>
                      <InputSelector generatorStep={b.buildingExteriorWallIsSanitizedSubsequently} register={register}
                                     errors={errors}/>
                    </InputGroup>
                  </form>
                )}

                {currentStep === findIndexOfBuildingStep('building_dach') && (
                  <form onSubmit={handleSubmit(onStepSubmit)}>
                    <InputGroup question={b.buildingRoofShape.question}>
                      <InputSelector generatorStep={b.buildingRoofShape} register={register} errors={errors}/>
                    </InputGroup>
                    <InputGroup question={b.buildingRoofOrientation.question}>
                      <Controller
                        control={control}
                        name={b.buildingRoofOrientation.id}
                        defaultValue={b.buildingRoofOrientation.value}
                        render={({ onChange, value }) => (
                          <ImageRotation
                            degrees={value}
                            onChange={onChange}
                            onFocus={onFocusRoofOrientation}
                            src={b.buildingRoofOrientation.images.foreground.src}
                            alt={b.buildingRoofOrientation.images.foreground.alt}
                            backgroundImage={b.buildingRoofOrientation.images.background.src}
                          />
                        )}
                      />
                    </InputGroup>
                    <InputGroup question={b.buildingAtticIsHeated.question}>
                      <InputSelector generatorStep={b.buildingAtticIsHeated} register={register} errors={errors}/>
                    </InputGroup>
                    {watchBuildingAtticIsHeated === values.buildingAtticIsHeated.JA && (
                      <ConditionalInputGroup question={b.buildingCeilingIsSanitizedSubsequently.question}>
                        <InputSelector generatorStep={b.buildingCeilingIsSanitizedSubsequently} register={register}
                                       errors={errors}/>
                      </ConditionalInputGroup>
                    )}
                    <InputGroup question={b.buildingCeilingFloorIsIsolatedSubsequently.question}>
                      <InputSelector generatorStep={b.buildingCeilingFloorIsIsolatedSubsequently} register={register}
                                     errors={errors}/>
                    </InputGroup>
                    {watchBuildingCeilingFloorIsIsolatedSubsequently === values.buildingCeilingFloorIsIsolatedSubsequently.JA && (
                      <ConditionalInputGroup isOptional={b.buildingCeilingFloorInstallationYear.isOptional}>
                        <InputSelector generatorStep={b.buildingCeilingFloorInstallationYear} register={register}
                                       errors={errors}/>
                        <InputSelector generatorStep={b.buildingCeilingFloorIsoloationStrength} register={register}
                                       errors={errors}/>
                      </ConditionalInputGroup>
                    )}
                    <InputGroup question={b.buildingRoofSlope.question} isOptional={b.buildingRoofSlope.isOptional}>
                      <InputSelector generatorStep={b.buildingRoofSlope} register={register} errors={errors}/>
                    </InputGroup>
                  </form>
                )}


                {currentStep === findIndexOfBuildingStep('building_fenster') && (
                  <form onSubmit={handleSubmit(onStepSubmit)}>
                    <InputGroup question={b.buildingExteriorWallWindowsAndDoorsStatement.question}>
                      <InputSelector generatorStep={b.buildingExteriorWallWindowsAndDoorsStatement} register={register}
                                     errors={errors}/>
                      <InputSelector generatorStep={b.buildingExteriorWallWindows} register={register} errors={errors}/>
                      <InputSelector generatorStep={b.buildingExteriorWallDoors} register={register} errors={errors}/>
                    </InputGroup>
                    {watchBuildingExteriorWallWindowsAndDoorsStatement === values.buildingExteriorWallWindowsAndDoorsStatement.DETAILLIERTE_ANGABE && (
                      <React.Fragment>
                        <ConditionalInputGroup question={b.buildingWindowAreaHeight.question}>
                          <InputSelector generatorStep={b.buildingWindowAreaHeight} register={register} errors={errors}/>
                        </ConditionalInputGroup>
                        <InputGroup question={b.buildingWindowInstallationYearStatement.question}>
                          <InputSelector generatorStep={b.buildingWindowInstallationYearStatement} register={register}
                                         errors={errors}/>
                        </InputGroup>
                        {watchBuildingWindowInstallationYearStatement === values.buildingWindowInstallationYearStatement.OTHER_YEAR && (
                          <ConditionalInputGroup>
                            <InputSelector generatorStep={b.buildingWindowInstallationYear} register={register}
                                           errors={errors}/>
                          </ConditionalInputGroup>
                        )}
                        <InputGroup question={b.buildingWindowGlazingType.question}>
                          <InputSelector generatorStep={b.buildingWindowGlazingType} register={register} errors={errors}/>
                        </InputGroup>
                      </React.Fragment>
                    )}
                  </form>
                )}


                {currentStep === findIndexOfBuildingStep('building_heizung') && (
                  <form onSubmit={handleSubmit(onStepSubmit)}>
                    <InputGroup question={b.buildingWhatHeating.question}>
                      <InputSelector generatorStep={b.buildingWhatHeating} register={register} errors={errors}/>
                    </InputGroup>
                    <InputGroup question={b.buildingHeatingType.question}>
                      <InputSelector generatorStep={b.buildingHeatingType} register={register} errors={errors}/>
                    </InputGroup>
                    <InputGroup question={b.buildingHeatingInstallationYearStatement.question}>
                      <InputSelector generatorStep={b.buildingHeatingInstallationYearStatement} register={register}
                                     errors={errors}/>
                      {watchBuildingHeatingInstallationYearStatement === values.buildingHeatingInstallationYearStatement.OTHER_YEAR && (
                        <ConditionalInputGroup>
                          <InputSelector generatorStep={b.buildingHeatingInstallationYear} register={register}
                                         errors={errors}/>
                        </ConditionalInputGroup>
                      )}
                    </InputGroup>
                    <InputGroup question={b.buildingHasHeatingValueTechnique.question}>
                      <InputSelector generatorStep={b.buildingHasHeatingValueTechnique} register={register}
                                     errors={errors}/>
                    </InputGroup>
                    <InputGroup question={b.buildingWhatBoiler.question}>
                      <InputSelector generatorStep={b.buildingWhatBoiler} register={register} errors={errors}/>
                    </InputGroup>
                    <InputGroup question={b.buildingBathingTubesCount.question}
                                isOptional={b.buildingBathingTubesCount.isOptional}>
                      <InputSelector generatorStep={b.buildingBathingTubesCount} register={register} errors={errors}/>
                      <InputSelector generatorStep={b.buildingShowersCount} register={register} errors={errors}/>
                    </InputGroup>
                    <InputGroup question={b.buildingHowRoomsHeated.question}>
                      <InputSelector generatorStep={b.buildingHowRoomsHeated} register={register} errors={errors}/>
                    </InputGroup>
                    <InputGroup question={b.buildingRadiatorsInstallationYearStatement.question}>
                      <InputSelector generatorStep={b.buildingRadiatorsInstallationYearStatement} register={register}
                                     errors={errors}/>
                    </InputGroup>
                    {watchBuildingRadiatorsInstallationYearStatement === values.buildingRadiatorsInstallationYearStatement.OTHER_YEAR && (
                      <ConditionalInputGroup question={b.buildingRadiatorsInstallationYear.question}>
                        <InputSelector generatorStep={b.buildingRadiatorsInstallationYear} register={register}
                                       errors={errors}/>
                      </ConditionalInputGroup>
                    )}
                    <InputGroup question={b.buildingUsesSolarthermics.question}>
                      <InputSelector generatorStep={b.buildingUsesSolarthermics} register={register} errors={errors}/>
                    </InputGroup>
                    {watchBuildingUsesSolarthermics === values.buildingUsesSolarthermics.JA && (
                      <ConditionalInputGroup question={b.buildingSolarthermicsCount.question}>
                        <InputSelector generatorStep={b.buildingSolarthermicsCount} register={register} errors={errors}/>
                      </ConditionalInputGroup>
                    )}
                    {watchBuildingUsesSolarthermics === values.buildingUsesSolarthermics.JA && (
                      <ConditionalInputGroup question={b.buildingWhatforSolarthermics.question}>
                        <InputSelector generatorStep={b.buildingWhatforSolarthermics} register={register} errors={errors}/>
                      </ConditionalInputGroup>
                    )}
                    <InputGroup question={b.buildingHasPhotovoltaics.question}>
                      <InputSelector generatorStep={b.buildingHasPhotovoltaics} register={register} errors={errors}/>
                    </InputGroup>
                    {watchBuildingHasPhotovoltaics === values.buildingHasPhotovoltaics.JA && (
                      <React.Fragment>
                        <ConditionalInputGroup question={b.buildingPhotovoltaicsCount.question}>
                          <InputSelector generatorStep={b.buildingPhotovoltaicsCount} register={register} errors={errors}/>
                        </ConditionalInputGroup>
                        <InputGroup question={b.buildingPhotovoltaicsMaxPower.question}
                                    isOptional={b.buildingPhotovoltaicsMaxPower.isOptional}>
                          <InputSelector generatorStep={b.buildingPhotovoltaicsMaxPower} register={register}
                                         errors={errors}/>
                        </InputGroup>
                        <InputGroup question={b.buildingPhotovoltaicsInstallationYearStatement.question}
                                    isOptional={b.buildingPhotovoltaicsInstallationYearStatement.isOptional}>
                          <InputSelector generatorStep={b.buildingPhotovoltaicsInstallationYearStatement} register={register}
                                         errors={errors}/>
                          {watchBuildingPhotovoltaicsInstallationYearStatement === values.buildingPhotovoltaicsInstallationYearStatement.OTHER_YEAR && (
                            <InputSelector generatorStep={b.buildingPhotovoltaicsInstallationYear} register={register}
                                           errors={errors}/>
                          )}
                        </InputGroup>
                        <InputGroup question={b.buildingPhotovoltaicsEnergyConsumption.question}
                                    isOptional={b.buildingPhotovoltaicsEnergyConsumption.isOptional}>
                          <InputSelector generatorStep={b.buildingPhotovoltaicsEnergyConsumption} register={register}
                                         errors={errors}/>
                        </InputGroup>
                      </React.Fragment>
                    )}
                    <InputGroup question={b.buildingHasChimney.question}>
                      <InputSelector generatorStep={b.buildingHasChimney} register={register} errors={errors}/>
                    </InputGroup>
                    {watchBuildingHasChimney === values.buildingHasChimney.JA && (
                      <ConditionalInputGroup question={b.buildingChimneyHasHeatingConnection.question}>
                        <InputSelector generatorStep={b.buildingChimneyHasHeatingConnection} register={register}
                                       errors={errors}/>
                      </ConditionalInputGroup>
                    )}
                    <InputGroup question={b.buildingWoodConsumption.question}
                                isOptional={b.buildingWoodConsumption.isOptional}>
                      <InputSelector generatorStep={b.buildingWoodConsumption} register={register} errors={errors}/>
                    </InputGroup>
                    <InputGroup question={b.buildingOilConsumption.question}
                                isOptional={b.buildingOilConsumption.isOptional}>
                      <InputSelector generatorStep={b.buildingOilConsumption} register={register} errors={errors}/>
                    </InputGroup>
                    <InputGroup question={b.buildingEnergyConsumption.question}
                                isOptional={b.buildingEnergyConsumption.isOptional}>
                      <InputSelector generatorStep={b.buildingEnergyConsumption} register={register} errors={errors}/>
                    </InputGroup>
                    <InputGroup question={b.buildingWantFunds.question}>
                      <InputSelector generatorStep={b.buildingWantFunds} register={register} errors={errors}/>
                    </InputGroup>
                  </form>
                )}

                {currentStep === findIndexOfBuildingStep('building_klimagereat') && (
                  <form onSubmit={handleSubmit(onStepSubmit)}>
                    <InputGroup question={b.buildingAirConditionerRoomCount.question}>
                      <InputSelector generatorStep={b.buildingAirConditionerRoomCount} register={register} errors={errors}/>
                    </InputGroup>
                    <InputGroup question={b.buildingAirConditionerArea.question}>
                      <InputSelector generatorStep={b.buildingAirConditionerArea} register={register} errors={errors}/>
                    </InputGroup>
                  </form>
                )}
              </Card>
            </div>
          )}


          {currentStep === findIndexOfBuildingStep('general_kontakt') && (
            <div className={`${styles['generator__building']} ${styles['generator__building_is-contact']}`}>
              <div className={styles['generator__float-img-container']}>
                <img className={styles['generator__float-img']} src={getFloatImage()} alt="Gebäude"/>
              </div>
              <form onSubmit={handleSubmit(onStepSubmit)}>
                <div className={styles['generator__contact-card-container']}>
                  <Card>
                    {/*<InputGroup>*/}
                    <div className={styles['contact-data']}>
                      <h2 className={styles['contact-data__heading']}>Kontakdaten</h2>
                      <div className={styles['contact-data__input-row']}>
                        <InputSelector generatorStep={contact.contactCompanyPrivate} register={register} errors={errors}/>
                      </div>
                      <div className={styles['contact-data__input-row']}>
                        <InputSelector generatorStep={contact.contactFirstName} register={register} errors={errors}/>
                        <InputSelector generatorStep={contact.contactLastName} register={register} errors={errors}/>
                      </div>
                      <div className={styles['contact-data__input-row']}>
                        <InputSelector generatorStep={contact.contactPostcode} register={register} errors={errors}/>
                        <InputSelector generatorStep={contact.contactCity} register={register} errors={errors}/>
                      </div>
                      <div className={styles['contact-data__input-row']}>
                        <InputSelector generatorStep={contact.contactStreet} register={register} errors={errors}/>
                      </div>
                      <div className={styles['contact-data__input-row']}>
                        <InputSelector generatorStep={contact.contactEmail} register={register} errors={errors}/>
                        <InputSelector generatorStep={contact.contactPhone} register={register} errors={errors}/>
                      </div>
                      <div className={styles['contact-data__input-row']}>
                        <InputSelector generatorStep={contact.contactMessage} register={register} errors={errors}/>
                      </div>
                      <div className={styles['contact-data__input-row']}>
                        <InputSelector generatorStep={contact.contactFiles} register={register} errors={errors}/>
                      </div>
                      <span className={styles['contact-data__file-info']}>
                        Die maximale Dateigröße beträgt 10MB
                      </span>
                      <span className={styles['contact-data__file-info']}>
                        Erlaubte Datentypen: .jpg, .png, .gif, .pdf, .dxf, .dwg
                      </span>
                    </div>
                    {/*</InputGroup>*/}
                  </Card>
                </div>
              </form>
            </div>
          )}
          <div className="wizard__controls">
            <div className="wizard__controls-empty" />
            <div className="wizard__controls-buttons">
              {!isFirstStep(currentStep) && (
                <Button className="wizard__left-button" color="primary" size="lg" onClick={decrementStep}>{'\u{003C}'} Zurück</Button>
              )}
              {isLastStep(currentStep, steps.length) ? (
                <Button className="wizard__right-button" color="primary" size="lg" onClick={handleLastStepChange}>Unverbindliche Anfrage jetzt abschicken</Button>
              ) : (
                <Button className="wizard__right-button" color="primary" size="lg" onClick={incrementStep}>Weiter {'\u{003E}'}</Button>
              )}
            </div>
          </div>
        </React.Fragment>
      )}

      {sendMailState.isLoading && (
        <Card>
          <div className={styles['loading']}>
            <div className={styles['loading__icon-container']}>
              <img className={styles['loading__icon']} src={loadingSpinner} alt="Ladeicon" />
            </div>
            <span className={styles['loading__text']}>Die Anfrage wird verschickt. Bitte warten...</span>
          </div>
        </Card>
      )}

      {sendMailState.hasSuccess && (
        <Card>
          <div className={styles['mailSuccess']}>
            <div className={styles['mailSuccess__text']}>
              <p className={styles['mailSuccess__text-line']}>Vielen Dank für Ihre Anfrage</p>
              <p className={styles['mailSuccess__text-line']}>Ihre Daten wurden erfolgreich übermittelt</p>
              <img src={iconCheckmarkSuccess} alt="Grünes Häkchen"/>
            </div>
            <Button
              href={pdfBlob && URL.createObjectURL(pdfBlob)}
              download="MD Heizsysteme - Zusammenfassung.pdf"
              className={styles['mailSuccess__download-pdf']}
              size="lg"
              color="primary"
            >
              Zusammenfassung als PDF herunterladen
            </Button>
          </div>
        </Card>
      )}

      {sendMailState.hasError && (
        <Card>
          <div className={styles['mailError']}>
            <span className={styles['mailError__text']}>Es ist leider ein Fehler aufgetreten. Die Anfrage wurde nicht versandt.</span>
            <span className={styles['mailError__description']}>{sendMailState.errorMessage}</span>
            <img src={iconCrossError} alt="Grünes Häkchen"/>
            <Button
              className={styles['mailError__back']}
              size="lg"
              color="primary"
              onClick={() => dispatchSendMail({type: 'reset'})}
            >
              Zurück zur Eingabe
            </Button>
          </div>
        </Card>
      )}
    </div>
  );
};

export default Generator;
