import { inputType } from '../components/form/constants';
import val from '../helper/validator';

const values = {
  buildingType: {
    EINFAMILIENHAUS: 'einfamilienhaus',
    EINFAMILIENHAUS_EINLIEGERWOHNUNG: 'einfamilienhausEinliegerwohnung',
    ZWEIFAMILIENHAUS: 'zweifamilienhaus',
    ZWEIFAMILIENHAUS_EINLIEGERWOHNUNG: 'zweifamilienhausEinliegerwohnung',
    MEHRFAMILIENHAUS: 'mehrfamilienhaus',
    MEHRFAMILIENHAUS_EINLIEGERWOHNUNG: 'mehrfamilienhausEinliegerwohnung',
  },
  buildingAnnexSituation: {
    FREISTEHEND: 'freistehend',
    DOPPELHAUS: 'doppelhaus'
  },
  buildingHasBasement: {
    NEIN: 'nein',
    JA: 'ja'
  },
  buildingBasementIsHeated: {
    NEIN: 'nein',
    JA: 'ja',
    TEILWEISE: 'teilweise'
  },
  buildingBasePlateIsIsolatedSubsequently: {
    NEIN: 'nein',
    JA: 'ja',
  },
  buildingBasementCeilingIsIsolatedSubsequently: {
    NEIN: 'nein',
    JA: 'ja',
  },
  buildingRoofShape: {
    SATTELDACH: 'satteldach',
    FLACHDACH: 'flachdach',
    WALMDACH: 'walmdach',
    PULTDACH: 'pultdach',
  },
  buildingAtticIsHeated: {
    NEIN: 'nein',
    JA: 'ja'
  },
  buildingExteriorWallWindowsAndDoorsStatement: {
    VEREINFACHTE_AUSGABE: 'vereinfachteAusgabe',
    DETAILLIERTE_ANGABE: 'detaillierteAngabe'
  },
  buildingWindowInstallationYearStatement: {
    LIKE_BUILDING_YEAR: 'likeBuildingYear',
    OTHER_YEAR: 'otherYear',
    UNKNOWN: 'unknown'
  },
  buildingHeatingInstallationYearStatement: {
    LIKE_BUILDING_YEAR: 'likeBuildingYear',
    OTHER_YEAR: 'otherYear',
    UNKNOWN: 'unknown'
  },
  buildingRadiatorsInstallationYearStatement: {
    LIKE_BUILDING_YEAR: 'likeBuildingYear',
    OTHER_YEAR: 'otherYear',
    UNKNOWN: 'unknown'
  },
  buildingCeilingFloorIsIsolatedSubsequently: {
    NEIN: 'nein',
    JA: 'ja'
  },
  buildingHasChimney: {
    NEIN: 'nein',
    JA: 'ja'
  },
  buildingUsesSolarthermics: {
    NEIN: 'nein',
    JA: 'ja'
  },
  buildingHasPhotovoltaics: {
    NEIN: 'nein',
    JA: 'ja'
  },
  buildingPhotovoltaicsInstallationYearStatement: {
    LIKE_INSTALLATION_YEAR: 'likeInstallationYear',
    OTHER_YEAR: 'otherYear'
  }
}

const generatorSteps = {
  // ----------
  // Step 1
  // ----------
  buildingLocation: {
    id: 'buildingLocation',
    type: inputType.TEXT,
    question: 'Wo planen Sie Ihr Bauobjekt?',
    initialValue: '',
    placeholder: 'Postleitzahl',
    exampleText: 'zB. 80805',
    validators: val.setValidators([val.isRequired(), val.isPLZ()])
  },
  buildingBuildYear: {
    id: 'buildingBuildYear',
    type: inputType.TEXT,
    question: 'Welches Baujahr hat Ihr Gebäude?',
    initialValue: '',
    placeholder: 'Jahr',
    exampleText: 'zB. 1975',
    validators: val.setValidators([val.isRequired(), val.isYear()])
  },
  buildingResidents: {
    id: 'buildingResidents',
    type: inputType.TEXT,
    question: 'Wie viele Personen wohnen in Ihrem Gebäude?',
    initialValue: '',
    placeholder: 'Personen',
    exampleText: 'zB. 4',
    validators: val.setValidators([val.isRequired(), val.isNumber()])
  },
  // ----------
  // Step 2
  // ----------
  buildingType: {
    id: 'buildingType',
    question: 'Um welchen Gebäudetyp handelt es sich?',
    values: [
      { id: values.buildingType.EINFAMILIENHAUS, name: 'Einfamilienhaus\xa0\xa0\xa0\xa0' }, // nbsp for alignment
      { id: values.buildingType.EINFAMILIENHAUS_EINLIEGERWOHNUNG, name: 'Einfamilienhaus inkl. Einliegerwohnung' },
      { id: values.buildingType.ZWEIFAMILIENHAUS, name: 'Zweifamilienhaus\xa0' },
      { id: values.buildingType.ZWEIFAMILIENHAUS_EINLIEGERWOHNUNG, name: 'Zweifamilienhaus inkl. Einliegerwohnung' },
      { id: values.buildingType.MEHRFAMILIENHAUS, name: 'Mehrfamilienhaus' },
      { id: values.buildingType.MEHRFAMILIENHAUS_EINLIEGERWOHNUNG, name: 'Mehrfamilienhaus inkl. Einliegerwohnung' },
    ],
    type: inputType.RADIO,
    validators: val.setValidators([val.isRequired()])
  },
  buildingHousingUnit: {
    id: 'buildingHousingUnit',
    type: inputType.TEXT,
    question: 'Wie viele Wohneinheiten hat Ihr Gebäude?',
    initialValue: '',
    placeholder: 'Wohneinheiten',
    exampleText: 'zB. 2',
    validators: val.setValidators([val.isRequired(), val.isNumber()])
  },
  buildingAnnexSituation: {
    id: 'buildingAnnexSituation',
    question: 'Wie ist die Anbausituation für Ihr Gebäude?',
    values: [
      {
        id: values.buildingAnnexSituation.FREISTEHEND,
        name: 'Freistehend',
        image: {
          src: require('../images/icon_freistehend.png').default,
          alt: 'Freistehendes Gebäude'
        }
      },
      {
        id: values.buildingAnnexSituation.DOPPELHAUS,
        name: 'Doppelhaus / Reihenhaus',
        image: {
          src: require('../images/icon_doppelhaus.png').default,
          alt: 'Doppelhaus / Reihenhaus'
        }
      },
    ],
    type: inputType.RADIO,
    validators: val.setValidators([val.isRequired()])
  },
  buildingFullStoreys: {
    id: 'buildingFullStoreys',
    type: inputType.TEXT,
    question: 'Wie viele Vollgeschosse hat Ihr Gebäude?',
    initialValue: '',
    placeholder: 'Anzahl Vollgeschosse',
    exampleText: 'ohne Keller und Geschoss mit Dachschräge',
    validators: val.setValidators([val.isRequired(), val.isNumber()])
  },
  buildingHeatableLivingArea: {
    id: 'buildingHeatableLivingArea',
    type: inputType.TEXT,
    question: 'Wie groß ist die beheizbare Wohnfläche?',
    initialValue: '',
    unit: `m\u00B2`,
    placeholder: 'Wohnfläche',
    exampleText: 'zB. 140',
    validators: val.setValidators([val.isRequired(), val.isNumber()])
  },
  buildingWallAdjoins: {
    id: 'buildingWallAdjoins',
    question: 'An welchen Wänden grenzt ein anderes Gebäude?',
    values: [
      { id: 'a', name: 'A' },
      { id: 'b', name: 'B' },
      { id: 'c', name: 'C' },
      { id: 'd', name: 'D' },
    ],
    type: inputType.CHECKBOX,
    validators: val.setValidators([val.isRequired()])
  },
  buildingCeilingHeight: {
    id: 'buildingCeilingHeight',
    type: inputType.TEXT,
    question: 'Welche Raumhöhe haben die Geschosse?',
    initialValue: '',
    unit: 'm',
    placeholder: 'Raumhöhe',
    exampleText: 'zB. 2,4',
    isOptional: true,
    validators: val.setValidators([val.isDecimalNumber()])
  },
  buildingHasBasement: {
    id: 'buildingHasBasement',
    question: 'Haben Sie einen Keller?',
    values: [
      { id: values.buildingHasBasement.NEIN, name: 'Nein' },
      { id: values.buildingHasBasement.JA, name: 'Ja' },
    ],
    type: inputType.RADIO,
    validators: val.setValidators([val.isRequired()])
  },
  buildingBasementIsHeated: {
    id: 'buildingBasementIsHeated',
    question: 'Ist der Keller beheizt?',
    values: [
      { id: values.buildingBasementIsHeated.NEIN, name: 'Nein' },
      { id: values.buildingBasementIsHeated.JA, name: 'Ja' },
      { id: values.buildingBasementIsHeated.TEILWEISE, name: 'Teilweise' },
    ],
    type: inputType.RADIO,
    validators: val.setValidators([val.isRequired()])
  },
  buildingBasePlateIsIsolatedSubsequently: {
    id: 'buildingBasePlateIsIsolatedSubsequently',
    question: 'Wurde die Bodenplatte nachträglich gedämmt?',
    values: [
      { id: values.buildingBasePlateIsIsolatedSubsequently.NEIN, name: 'Nein' },
      { id: values.buildingBasePlateIsIsolatedSubsequently.JA, name: 'Ja' },
    ],
    type: inputType.RADIO,
    validators: val.setValidators([val.isRequired()])
  },
  buildingBasementCeilingIsIsolatedSubsequently: {
    id: 'buildingBasementCeilingIsIsolatedSubsequently',
    question: 'Wurde die Kellerdecke nachträglich gedämmt?',
    values: [
      { id: values.buildingBasementCeilingIsIsolatedSubsequently.NEIN, name: 'Nein' },
      { id: values.buildingBasementCeilingIsIsolatedSubsequently.JA, name: 'Ja' },
    ],
    type: inputType.RADIO,
    validators: val.setValidators([val.isRequired()])
  },
  buildingBasementCeilingInstallationYear: {
    id: 'buildingBasementCeilingInstallationYear',
    parentId: 'buildingBasementCeilingIsIsolatedSubsequently',
    type: inputType.TEXT,
    label: 'Einbaujahr',
    pdfLabel: '\u{00BB} Kellerdecke - Einbaujahr',
    initialValue: '',
    placeholder: 'Einbaujahr',
    exampleText: 'zB. 1990',
    validators: val.setValidators([val.isRequired(), val.isNumber()])
  },
  buildingBasementCeilingIsoloationStrength: {
    id: 'buildingBasementCeilingIsoloationStrength',
    parentId: 'buildingBasementCeilingIsIsolatedSubsequently',
    label: 'Dämmstärke',
    pdfLabel: '\u{00BB} Kellerdecke - Dämmstärke',
    values: [
      { id: '4', name: '4cm (gering)' },
      { id: '8', name: '8cm' },
      { id: '10', name: '10cm (mittel)' },
      { id: '12', name: '12cm' },
      { id: '16', name: '16cm (stark)' },
    ],
    type: inputType.RADIO,
    validators: val.setValidators([val.isRequired()])
  },
  // ----------
  // Step 3
  // ----------
  buildingConstructionMasonry: {
    id: 'buildingConstructionMasonry',
    question: 'Wie ist die Bauweise des Mauerwerks',
    values: [
      { id: 'massivbauweise', name: 'Massivbauweise' },
      { id: 'holzstaenderbauweise', name: 'Holzständerbauweise' },
      { id: 'fachwerkhaus', name: 'Fachwerkhaus' },
    ],
    type: inputType.RADIO,
    validators: val.setValidators([val.isRequired()])
  },
  buildingFacadeExecution: {
    id: 'buildingFacadeExecution',
    question: 'Wie ist Fassade ausgeführt?',
    values: [
      { id: 'verputzteFassade', name: 'Verputzte Fassade' },
      { id: 'klinkerFassade', name: 'Klinkerfassade' },
    ],
    type: inputType.RADIO,
    validators: val.setValidators([val.isRequired()])
  },
  buildingExteriorWallIsSanitizedSubsequently: {
    id: 'buildingExteriorWallIsSanitizedSubsequently',
    question: 'Wurde die Außenwand nachträglich saniert?',
    values: [
      { id: 'putzUndAnstrich', name: 'Putz und Anstrich neu' },
      { id: 'daemmmassnahmen', name: 'Dämmmaßnahmen' },
    ],
    type: inputType.RADIO,
    validators: val.setValidators([val.isRequired()])
  },
  // ----------
  // Step 4
  // ----------
  buildingRoofShape: {
    id: 'buildingRoofShape',
    question: 'Welche Dachform hat Ihr Gebäude?',
    values: [
      {
        id: values.buildingRoofShape.SATTELDACH,
        name: 'Satteldach',
        image: {
          src: require('../images/icon_satteldach.png').default,
          alt: 'Satteldach'
        }
      },
      {
        id: values.buildingRoofShape.FLACHDACH,
        name: 'Flachdach',
        image: {
          src: require('../images/icon_flachdach.png').default,
          alt: 'Flachdach'
        }
      },
      {
        id: values.buildingRoofShape.WALMDACH,
        name: 'Walmdach',
        image: {
          src: require('../images/icon_walmdach.png').default,
          alt: 'Walmdach'
        }
      },
      {
        id: values.buildingRoofShape.PULTDACH,
        name: 'Pultdach',
        image: {
          src: require('../images/icon_pultdach.png').default,
          alt: 'Pultdach'
        }
      },
    ],
    type: inputType.RADIO,
    validators: val.setValidators([val.isRequired()])
  },
  buildingRoofOrientation: {
    id: 'buildingRoofOrientation',
    question: 'Wie ist das Dach ausgerichtet?',
    type: inputType.ROTATED_IMAGE,
    images: {
      foreground: {
        src: require('../images/icon_ausrichtung-haus.png').default,
        alt: 'Gebäudedach'
      },
      background: {
        src: require('../images/icon_ausrichtung-kompass.png').default,
        alt: 'Himmelsrichtungen'
      }
    },
    value: 0
  },
  buildingAtticIsHeated: {
    id: 'buildingAtticIsHeated',
    question: 'Ist das Dachgeschoss beheizt?',
    values: [
      { id: values.buildingAtticIsHeated.NEIN, name: 'Nein' },
      { id: values.buildingAtticIsHeated.JA, name: 'Ja' },
    ],
    type: inputType.RADIO,
    validators: val.setValidators([val.isRequired()])
  },
  buildingCeilingIsSanitizedSubsequently: {
    id: 'buildingCeilingIsSanitizedSubsequently',
    question: 'Wurde das Dach nachträglich saniert?',
    values: [
      { id: 'nein', name: 'Nein' },
      { id: 'dachziegel', name: 'Dachziegel neu' },
      { id: 'daemmmassnahmen', name: 'Dämmmaßnahmen' },
      { id: 'beides', name: 'Beides' },
    ],
    type: inputType.RADIO,
    validators: val.setValidators([val.isRequired()])
  },
  buildingCeilingFloorIsIsolatedSubsequently: {
    id: 'buildingCeilingFloorIsIsolatedSubsequently',
    question: 'Wurde die oberste Geschossdecke nachträglich gedämmt?',
    values: [
      { id: values.buildingCeilingFloorIsIsolatedSubsequently.NEIN, name: 'Nein' },
      { id: values.buildingCeilingFloorIsIsolatedSubsequently.JA, name: 'Ja' },
    ],
    type: inputType.RADIO,
    validators: val.setValidators([val.isRequired()])
  },
  buildingCeilingFloorInstallationYear: {
    id: 'buildingCeilingFloorInstallationYear',
    parentId: 'buildingCeilingFloorIsIsolatedSubsequently',
    type: inputType.TEXT,
    label: 'Einbaujahr',
    pdfLabel: '\u{00BB} Oberste Geschossdecke - Einbaujahr',
    initialValue: '',
    placeholder: 'Einbaujahr',
    exampleText: 'zB. 1990',
    isOptional: true,
    validators: val.setValidators([val.isNumber(), val.isYear()])
  },
  buildingCeilingFloorIsoloationStrength: {
    id: 'buildingCeilingFloorIsoloationStrength',
    parentId: 'buildingCeilingFloorIsIsolatedSubsequently',
    label: 'Dämmstärke',
    pdfLabel: '\u{00BB} Oberste Geschossdecke - Dämmstärke',
    values: [
      { id: '4', name: '4cm (gering)' },
      { id: '8', name: '8cm' },
      { id: '10', name: '10cm (mittel)' },
      { id: '12', name: '12cm' },
      { id: '16', name: '16cm (stark)' },
    ],
    type: inputType.RADIO,
    isOptional: true,
  },
  buildingRoofSlope: {
    id: 'buildingRoofSlope',
    question: 'Welche Neigung hat das Dach?',
    type: inputType.TEXT,
    initialValue: '',
    unit: 'Grad',
    placeholder: 'Neigung',
    exampleText: 'zB. 45',
    isOptional: true,
    validators: val.setValidators([val.isNumber()])
  },
  // ----------
  // Step 5
  // ----------
  buildingExteriorWallWindowsAndDoorsStatement: {
    id: 'buildingExteriorWallWindowsAndDoorsStatement',
    question: 'Wie viele Fenster und Türen besitzt Ihr Gebäude an der Außenwand?',
    values: [
      { id: values.buildingExteriorWallWindowsAndDoorsStatement.VEREINFACHTE_AUSGABE, name: 'Vereinfachte Angabe' },
      { id: values.buildingExteriorWallWindowsAndDoorsStatement.DETAILLIERTE_ANGABE, name: 'Detaillierte Angabe' },
    ],
    type: inputType.RADIO,
    validators: val.setValidators([val.isRequired()])
  },
  buildingExteriorWallWindows: {
    id: 'buildingExteriorWallWindows',
    parentId: 'buildingExteriorWallWindowsAndDoorsStatement',
    type: inputType.TEXT,
    initialValue: '',
    label: 'Fenster (optional)',
    pdfLabel: '\u{00BB} Außenwand - Fenster',
    placeholder: 'Fensteranzahl',
    exampleText: 'zB. 12',
    validators: val.setValidators([val.isNumber()])
  },
  buildingExteriorWallDoors: {
    id: 'buildingExteriorWallDoors',
    parentId: 'buildingExteriorWallWindowsAndDoorsStatement',
    type: inputType.TEXT,
    initialValue: '',
    label: 'Tür(en) (optional)',
    pdfLabel: '\u{00BB} Außenwand - Tür(en)',
    placeholder: 'Türanzahl',
    exampleText: 'zB. 2',
    validators: val.setValidators([val.isNumber()])
  },
  buildingWindowAreaHeight: {
    id: 'buildingWindowAreaHeight',
    question: 'Wie hoch schätzen Sie Ihre Fensterfläche insgesamt ein?',
    values: [
      { id: 'low', name: 'gering\nkeine Bodentiefen' },
      { id: 'medium', name: 'mittel\nnormale und bodentiefe' },
      { id: 'high', name: 'hoch\nviele bodentiefe' },
    ],
    type: inputType.RADIO,
    validators: val.setValidators([val.isRequired()])
  },
  buildingWindowInstallationYearStatement: {
    id: 'buildingWindowInstallationYearStatement',
    question: 'Einbaujahr des Fensters?',
    values: [
      { id: values.buildingWindowInstallationYearStatement.LIKE_BUILDING_YEAR, name: 'Wie Gebäudejahr' },
      { id: values.buildingWindowInstallationYearStatement.OTHER_YEAR, name: 'Anderes Einbaujahr' },
      { id: values.buildingWindowInstallationYearStatement.UNKNOWN, name: 'Unbekannt' },
    ],
    type: inputType.RADIO,
    validators: val.setValidators([val.isRequired()])
  },
  buildingWindowInstallationYear: {
    id: 'buildingWindowInstallationYear',
    parentId: 'buildingWindowInstallationYearStatement',
    type: inputType.TEXT,
    initialValue: '',
    label: 'Einbaujahr',
    pdfLabel: '\u{00BB} Fenster - Einbaujahr',
    placeholder: 'Einbaujahr',
    exampleText: 'zB. 1998',
    validators: val.setValidators([val.isRequired(), val.isNumber(), val.isYear()])
  },
  buildingWindowGlazingType: {
    id: 'buildingWindowGlazingType',
    question: 'Wie hoch schätzen Sie Ihre Fensterfläche insgesamt ein?',
    values: [
      { id: 'singleGlazing', name: 'Einfachverglasung' },
      { id: 'doubleGlazing', name: '2-Fach-Verglasung' },
      { id: 'doubleHeatProtectionGlazing', name: '2-Fach-Wärmeschutzverglasung' },
      { id: 'tripleGlazing', name: '3-Fach-Verglasung' },
    ],
    type: inputType.RADIO,
    validators: val.setValidators([val.isRequired()])
  },
  // ----------
  // Step 6
  // ----------
  buildingWhatHeating: {
    id: 'buildingWhatHeating',
    question: 'Womit wird das Gebäude beheizt?',
    values: [
      { id: 'gas', name: 'Gas' },
      { id: 'oil', name: 'Öl' },
      { id: 'pellets', name: 'Pellets' },
      { id: 'geothermics', name: 'Erdwärmepumpe' },
      { id: 'airthermics', name: 'Luftwärmepumpe' },
      { id: 'electric', name: 'Elektroöfen' },
    ],
    type: inputType.RADIO,
    validators: val.setValidators([val.isRequired()])
  },
  buildingHeatingType: {
    id: 'buildingHeatingType',
    question: 'Ausführung der Heizung?',
    values: [
      { id: 'centralHeating', name: 'Zentralheizung' },
      { id: 'roomHeating', name: 'Raumeinzelöfen' },
    ],
    type: inputType.RADIO,
    validators: val.setValidators([val.isRequired()])
  },
  buildingHeatingInstallationYearStatement: {
    id: 'buildingHeatingInstallationYearStatement',
    question: 'Einbaujahr der Heizung?',
    values: [
      { id: values.buildingHeatingInstallationYearStatement.LIKE_BUILDING_YEAR, name: 'Wie Gebäudejahr' },
      { id: values.buildingHeatingInstallationYearStatement.OTHER_YEAR, name: 'Anderes Einbaujahr' },
      { id: values.buildingHeatingInstallationYearStatement.UNKNOWN, name: 'Unbekannt' },
    ],
    type: inputType.RADIO,
    validators: val.setValidators([val.isRequired()])
  },
  buildingHeatingInstallationYear: {
    id: 'buildingHeatingInstallationYear',
    parentId: 'buildingHeatingInstallationYearStatement',
    type: inputType.TEXT,
    initialValue: '',
    label: 'Einbaujahr',
    pdfLabel: '\u{00BB} Heizung - Einbaujahr',
    placeholder: 'Einbaujahr',
    exampleText: 'zB. 1998',
    validators: val.setValidators([val.isRequired(), val.isNumber(), val.isYear()])
  },
  buildingHasHeatingValueTechnique: {
    id: 'buildingHasHeatingValueTechnique',
    question: 'Hat Ihre Heizung Heizwert- oder Brennwerttechnik?',
    values: [
      { id: 'heatingValue', name: 'Heizwerttechnik' },
      { id: 'burningValue', name: 'Brennwerttechnik' },
      { id: 'unknown', name: 'Unbekannt' },
    ],
    type: inputType.RADIO,
    validators: val.setValidators([val.isRequired()])
  },
  buildingWhatBoiler: {
    id: 'buildingWhatBoiler',
    question: 'Womit erfolgt die Warmwasserbereitung?',
    values: [
      { id: 'heater', name: 'Heizung' },
      { id: 'electricalFlowHeater', name: 'Elektrische Durchlauferhitzer' },
      { id: 'electricalStorage', name: 'Elektrischer Speicher' },
      { id: 'unknown', name: 'Unbekannt' },
    ],
    type: inputType.RADIO,
    validators: val.setValidators([val.isRequired()])
  },
  buildingBathingTubesCount: {
    id: 'buildingBathingTubesCount',
    type: inputType.TEXT,
    question: 'Wie viele Badewannen und Duschen sind im Gebäude?',
    initialValue: '',
    label: 'Badewanne(n)',
    pdfLabel: 'Wie viele Badewannen sind im Gebäude?',
    placeholder: 'Anzahl Badewannen',
    exampleText: 'zB. 2',
    isOptional: true,
    validators: val.setValidators([val.isNumber()])
  },
  buildingShowersCount: {
    id: 'buildingShowersCount',
    parentId: 'buildingBathingTubesCount',
    type: inputType.TEXT,
    initialValue: '',
    label: 'Dusche(n)',
    pdfLabel: 'Wie viele Duschen sind im Gebäude?',
    placeholder: 'Anzahl Duschen',
    exampleText: 'zB. 2',
    isOptional: true,
    validators: val.setValidators([val.isNumber()])
  },
  buildingHowRoomsHeated: {
    id: 'buildingHowRoomsHeated',
    question: 'Wie werden die Räume beheizt?',
    values: [
      { id: 'heater', name: 'Heizkörper' },
      { id: 'floorHeater', name: 'Fußbodenheizung' },
      { id: 'both', name: 'Beides' },
    ],
    type: inputType.RADIO,
    validators: val.setValidators([val.isRequired()])
  },
  buildingRadiatorsInstallationYearStatement: {
    id: 'buildingRadiatorsInstallationYearStatement',
    question: 'Einbaujahr der Heizkörper / Fußbodenheizung?',
    values: [
      { id: values.buildingRadiatorsInstallationYearStatement.LIKE_BUILDING_YEAR, name: 'Wie Gebäudejahr' },
      { id: values.buildingRadiatorsInstallationYearStatement.OTHER_YEAR, name: 'Anderes Einbaujahr' },
      { id: values.buildingRadiatorsInstallationYearStatement.UNKNOWN, name: 'Unbekannt' },
    ],
    type: inputType.RADIO,
    validators: val.setValidators([val.isRequired()])
  },
  buildingRadiatorsInstallationYear: {
    id: 'buildingRadiatorsInstallationYear',
    parentId: 'buildingRadiatorsInstallationYearStatement',
    type: inputType.TEXT,
    initialValue: '',
    label: 'Einbaujahr',
    pdfLabel: '\u{00BB} Heizkörper - Einbaujahr',
    placeholder: 'Einbaujahr',
    exampleText: 'zB. 1998',
    validators: val.setValidators([val.isRequired(), val.isNumber(), val.isYear()])
  },
  buildingUsesSolarthermics: {
    id: 'buildingUsesSolarthermics',
    question: 'Nutzen Sie Solarthermie?',
    values: [
      { id: values.buildingUsesSolarthermics.NEIN, name: 'Nein' },
      { id: values.buildingUsesSolarthermics.JA, name: 'Ja' },
    ],
    type: inputType.RADIO,
    validators: val.setValidators([val.isRequired()])
  },
  buildingSolarthermicsCount: {
    id: 'buildingSolarthermicsCount',
    question: 'Wie viele Solarmodule setzen Sie ein?',
    type: inputType.TEXT,
    initialValue: '',
    placeholder: 'Anzahl Solarmodule',
    exampleText: 'zB. 2',
    validators: val.setValidators([val.isNumber()])
  },
  buildingWhatforSolarthermics: {
    id: 'buildingWhatforSolarthermics',
    question: 'Wofür nutzen Sie Solarthermie?',
    values: [
      { id: 'warmwater', name: 'Solare Warmwasserunterstützung' },
      { id: 'warmAndHeatingwater', name: 'Solare Warm- und Heizwasserunterstützung' },
    ],
    type: inputType.RADIO,
    validators: val.setValidators([val.isRequired()])
  },
  buildingHasPhotovoltaics: {
    id: 'buildingHasPhotovoltaics',
    question: 'Haben Sie eine Photovoltaikanlage?',
    values: [
      { id: values.buildingHasPhotovoltaics.NEIN, name: 'Nein' },
      { id: values.buildingHasPhotovoltaics.JA, name: 'Ja' },
    ],
    type: inputType.RADIO,
    validators: val.setValidators([val.isRequired()])
  },
  buildingPhotovoltaicsCount: {
    id: 'buildingPhotovoltaicsCount',
    type: inputType.TEXT,
    initialValue: '',
    question: 'Wie viele Photovoltaikmodule setzen Sie ein?',
    placeholder: 'Anzahl Photovoltaikmodule',
    exampleText: 'zB. 2',
    validators: val.setValidators([val.isNumber()])
  },
  buildingPhotovoltaicsMaxPower: {
    id: 'buildingPhotovoltaicsMaxPower',
    type: inputType.TEXT,
    initialValue: '',
    question: 'Wie hoch ist die Spitzenleistung Ihrer Photovoltaikanlage?',
    placeholder: 'Spitzenleistung',
    unit: 'kWp',
    // exampleText: 'zB. 2',
    isOptional: true,
    validators: val.setValidators([val.isNumber(), val.isDecimalNumber()])
  },
  buildingPhotovoltaicsInstallationYearStatement: {
    id: 'buildingPhotovoltaicsInstallationYearStatement',
    type: inputType.RADIO,
    question: 'Einbaujahr Ihrer Photovoltaikanlage?',
    validators: val.setValidators([val.isRequired()]),
    values: [
      { id: values.buildingPhotovoltaicsInstallationYearStatement.LIKE_INSTALLATION_YEAR, name: 'Wie Einbaujahr' },
      { id: values.buildingPhotovoltaicsInstallationYearStatement.OTHER_YEAR, name: 'Anderes Einbaujahr' },
    ],
  },
  buildingPhotovoltaicsInstallationYear: {
    id: 'buildingPhotovoltaicsInstallationYear',
    type: inputType.TEXT,
    initialValue: '',
    placeholder: 'Einbaujahr',
    exampleText: 'zB. 1998',
    validators: val.setValidators([val.isNumber(), val.isYear(), val.isRequired()])
  },
  buildingPhotovoltaicsEnergyConsumption: {
    id: 'buildingPhotovoltaicsEnergyConsumption',
    type: inputType.TEXT,
    initialValue: '',
    question: 'Wie viel Energie erzeugt Ihre Photovoltaikanlage pro Jahr?',
    placeholder: 'Stromertrag',
    // exampleText: 'zB. 1998',
    unit: 'kWh',
    isOptional: true,
    validators: val.setValidators([val.isNumber(), val.isDecimalNumber()])
  },
  buildingHasChimney: {
    id: 'buildingHasChimney',
    question: 'Haben Sie einen Kaminofen?',
    values: [
      { id: values.buildingHasChimney.NEIN, name: 'Nein' },
      { id: values.buildingHasChimney.JA, name: 'Ja' },
    ],
    type: inputType.RADIO,
    validators: val.setValidators([val.isRequired()])
  },
  buildingChimneyHasHeatingConnection: {
    id: 'buildingChimneyHasHeatingConnection',
    question: 'Hat Ihr Kaminofen einen Heizungsanschluss?',
    values: [
      { id: 'without', name: 'ohne Heizungsanschluss' },
      { id: 'with', name: 'mit Heizungsanschluss' },
    ],
    type: inputType.RADIO,
    validators: val.setValidators([val.isRequired()])
  },
  buildingWoodConsumption: {
    id: 'buildingWoodConsumption',
    type: inputType.TEXT,
    initialValue: '',
    question: 'Wie hoch ist Ihr Holzverbrauch pro Jahr?',
    placeholder: 'Holzverbrauch',
    // exampleText: 'zB. 1998',
    unit: 'SRM',
    isOptional: true,
    validators: val.setValidators([val.isNumber(), val.isDecimalNumber()])
  },
  buildingOilConsumption: {
    id: 'buildingOilConsumption',
    type: inputType.TEXT,
    initialValue: '',
    question: 'Wie hoch ist Ihr Ölverbrauch pro Jahr?',
    placeholder: 'Ölverbrauch',
    // exampleText: 'zB. 1998',
    unit: 'Liter',
    isOptional: true,
    validators: val.setValidators([val.isNumber(), val.isDecimalNumber()])
  },
  buildingEnergyConsumption: {
    id: 'buildingEnergyConsumption',
    type: inputType.TEXT,
    initialValue: '',
    question: 'Wie hoch ist Ihr Stromverbauch pro Jahr?',
    placeholder: 'Stromverbauch',
    // exampleText: 'zB. 1998',
    unit: 'kWh',
    isOptional: true,
    validators: val.setValidators([val.isNumber(), val.isDecimalNumber()])
  },
  buildingWantFunds: {
    id: 'buildingWantFunds',
    question: 'Sollen Fördermittel in Anspruch genommen werden?',
    values: [
      { id: 'no', name: 'Nein' },
      { id: 'yes', name: 'Ja' },
    ],
    type: inputType.RADIO,
    validators: val.setValidators([val.isRequired()])
  },
  // buildingRoofTilt: {
  //   id: 'buildingRoofTilt',
  //   type: inputType.TEXT,
  //   question: 'Welche Neigung hat das Dach?',
  //   initialValue: '',
  //   placeholder: 'Neigung',
  //   unit: 'Grad',
  //   exampleText: 'zB. 45',
  //   validators: val.setValidators([val.isRequired(), val.isNumber()])
  // }

  // ----------
  // Step 7
  // ----------
  buildingAirConditionerRoomCount: {
    id: 'buildingAirConditionerRoomCount',
    type: inputType.TEXT,
    question: 'Wie viele Räume sollen klimatisiert werden?',
    initialValue: '',
    placeholder: 'Räume',
    exampleText: 'zB. 4',
    validators: val.setValidators([val.isRequired(), val.isNumber()])
  },
  buildingAirConditionerArea: {
    id: 'buildingAirConditionerArea',
    type: inputType.TEXT,
    question: 'Wie groß ist die gesamte Fläche der zu klimatisierenden Räume?',
    initialValue: '',
    unit: `m\u00B2`,
    placeholder: 'Fläche',
    exampleText: 'zB. 100',
    validators: val.setValidators([val.isRequired(), val.isDecimalNumber()])
  },
}

const steps = [
  {
    id: 'building_allgemein',
    title: 'Allgemein'
  },
  {
    id: 'building_gebaeudeAllgemein',
    title: 'Gebäude Allgemein'
  },
  {
    id: 'building_aussenwand',
    title: 'Außenwand'
  },
  {
    id: 'building_dach',
    title: 'Dach'
  },
  {
    id: 'building_fenster',
    title: 'Fenster'
  },
  {
    id: 'building_heizung',
    title: 'Heizung'
  },
  {
    id: 'building_klimagereat',
    title: 'Klimagerät'
  },
]

export default generatorSteps;
export { values, steps }
