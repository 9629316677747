import React from 'react';
import fontRobotoNormalNormal from '../../fonts/Roboto/Roboto-Regular.ttf';
import fontRobotoNormalMedium from '../../fonts/Roboto/Roboto-Medium.ttf';
import fontMontserratNormalNormal from '../../fonts/Montserrat/Montserrat-Regular.ttf';
import fontMontserratNormalSemiBold from '../../fonts/Montserrat/Montserrat-SemiBold.ttf';
import { Page, Text, Document, StyleSheet, Font } from '@react-pdf/renderer';
import { colors } from './styles';
import generatorSteps, { steps } from '../../data/building';
import { contact, general } from '../../data/general';
import Row from './components/row';
import Step from './components/step';
import Header from './components/header';
import Heading from './components/heading';
import RotatedImage from './components/rotatedImage';
import { inputType } from '../form/constants';
import Section from './components/section';

Font.register({
  family: 'Roboto', fonts: [
      { src: fontRobotoNormalNormal },
      { src: fontRobotoNormalMedium, fontWeight: 500 },
    ]
})

Font.register({
  family: 'Montserrat', fonts: [
    { src: fontMontserratNormalNormal },
    { src: fontMontserratNormalSemiBold, fontWeight: 600 }
  ]
})

// Create styles
const styles = StyleSheet.create({
  page: {
    fontFamily: 'Roboto',
    padding: '54 37 104 54',
    color: colors.DARK_2
  }
});

const PdfExport = ({ stateMachineState: state }) => {
  const renderQuestion = (inputObject) => {
    if (inputObject.pdfLabel) {
      return inputObject.pdfLabel
    } else {
      return inputObject.question
    }
  }

  const renderAnswer = (inputObject, originalValue) => {
    if (!originalValue) return <Text>-- Keine Angabe --</Text>;

    let answer = '';
    const unit = inputObject.unit;

    if (inputObject.type === inputType.ROTATED_IMAGE) {
      return <RotatedImage degrees={originalValue} />
    }

    if (inputObject.values) {
      if (Array.isArray(originalValue)) {
        originalValue.forEach((originalItem, index) => {
          answer += inputObject.values.find(value => value.id === originalItem).name;
          if (index < (originalValue.length - 1)) answer += ', '
        })
      } else {
        answer = inputObject.values.find(value => value.id === originalValue).name
      }
    }

    if (!answer) answer = originalValue;
    return <Text>{answer} {unit && (<Text>{unit}</Text>)}</Text>
  }

  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <Header />
        <Heading headingSize="h1" heading="Zusammenfassung" />

        <Section heading="Vorhaben" >
          {/* eslint-disable-next-line array-callback-return */}
          {Object.keys(general).map(key => {
            if (state.formStateBuilding['general_vorhaben'][key]) {
              return (
                <Row
                  question={renderQuestion(general[key])}
                  value={renderAnswer(general[key], state.formStateBuilding['general_vorhaben'][key])}
                />
              )
            }
          })}
        </Section>

        <Section heading="Gebäude">
          {steps.map(step => {
            // eslint-disable-next-line array-callback-return
            if (state.formStateBuilding[step.id] == null) return;

            return <Step heading={step.title}>
              {/* eslint-disable-next-line array-callback-return */}
              {Object.keys(generatorSteps).map((key, index) => {
                if (state.formStateBuilding[step.id][key] != null) {
                  return (
                    <Row
                      question={renderQuestion(generatorSteps[key], index)}
                      value={renderAnswer(generatorSteps[key], state.formStateBuilding[step.id][key])}
                      unit={generatorSteps[key].unit}
                    />
                  )
                }
              })}
            </Step>
          })}
        </Section>

        <Section heading="Kontaktdaten" breakPage={true} >
          {/* eslint-disable-next-line array-callback-return */}
          {Object.keys(contact).map(key => {
            if (key === contact.contactFiles.id) return null;
            if (state.formStateBuilding['general_kontakt'][key]) {
              return (
                <Row
                  question={renderQuestion(contact[key])}
                  value={renderAnswer(contact[key], state.formStateBuilding['general_kontakt'][key])}
                />
              )
            }
          })}
        </Section>
      </Page>
    </Document>
  );
};

export default PdfExport;
