import React from 'react';
import styles from './conditionalInputGroup.module.scss';

const ConditionalInputGroup = ({ question, children, isOptional }) => {
  return (
    <div className={styles['conditional-input-group']}>
      <span className={styles['conditional-input-group__question']}>{question} {isOptional && (<em>(Optional)</em>)}</span>
      <div className={styles['conditional-input-group__children']}>
        {children}
      </div>
    </div>
  );
};

export default ConditionalInputGroup;
