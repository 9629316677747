import React from 'react';
import hostConfig from '../config/host'
import './footer.scss';
import Container from './layout/container';

const Footer = () => {
  const getYear = () => {
    const date = new Date();
    return date.getFullYear();
  }

  return (
    <footer className="footer">
      <Container>
        <div className="footer__content">
          <span className="footer__copyright-container">
            <span className="footer__copyright-item">&copy; {getYear()} &laquo;MD Heizsysteme GmbH&raquo;&nbsp;</span>
            <span className="footer__copyright-item">Alle Rechte vorbehalten |&nbsp;</span>
            <span className="footer__copyright-item">
               designed by
              <a href={hostConfig.mediaoberbergURL} className="footer__mo-link" target="_blank" rel="noopener noreferrer">
              <img className="footer__mo-img" src={require('../images/mediaoberberg_logo.svg').default} alt="Logo" />
            </a>
            </span>

          </span>
          <ul className="footer__links">
            <li className="footer__link-item">
              <a className="footer__link" href={`${hostConfig.mainPageURL}/Impressum.html`} target="_blank" rel="noopener noreferrer">Impressum</a>
              &nbsp;|&nbsp;
            </li>
            <li className="footer__link-item">
              <a className="footer__link" href={`${hostConfig.mainPageURL}/datenschutz.html`} target="_blank" rel="noopener noreferrer">Datenschutzerklärung</a>
              &nbsp;|&nbsp;
            </li>
            <li className="footer__link-item">
              <a className="footer__link" href={`${hostConfig.mainPageURL}/agb.html`} target="_blank" rel="noopener noreferrer">AGB</a>
            </li>
          </ul>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
