import React from 'react';
import styles from './inputGroup.module.scss';

const InputGroup = ({ question, children, isOptional }) => {
  return (
    <div className={styles['input-group']}>
      <span className={styles['input-group__question']}>{question} {isOptional && (<em>(Optional)</em>)}</span>
      <div className={styles['input-group__children']}>
        {children}
      </div>
    </div>
  );
};

export default InputGroup;
