import React from 'react';
import Checkbox from './checkbox';
import styles from './checkboxGroup.module.scss';

const CheckboxGroup = ({ name, label, values, register, error }) => {
  return (
    <div className={styles['checkbox-group']}>
      {label && (
        <span className={styles['checkbox-group__group-label']}>{label}</span>
      )}
      <div className={styles['checkbox-group__items']}>
        {values.map(value => (
          <div key={value.id} className={styles['checkbox-group__item']}>
            <Checkbox name={name} id={value.id} label={value.name} register={register} hasError={error}/>
          </div>
        ))}
      </div>
      <div className={styles['checkbox-group__error-container']}>
        <span className={styles['checkbox-group__error-message']}>{error?.message}</span>
      </div>
    </div>
  );
};

export default CheckboxGroup;
