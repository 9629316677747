const mdHeizsysteme = {
  address: {
    street: 'Kirchstraße 7',
    city: 'Morsbach',
    postcode: '51597'
  },
  phone: {
    code: '+491799399400',
    text: '0179 9399400'
  },
  email: 'info@md-heizsysteme.de'
}

export default mdHeizsysteme;
