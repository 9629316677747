import React from 'react';
import PropTypes from 'prop-types';
import styles from './file.module.scss';

const File = ({ name, label, register, error, multiple, accept }) => {
  return (
    <div className={styles['file']}>
      <label htmlFor={name}>{label}</label>
      <input
        className={styles['file__input']}
        accept={accept.join()}
        type="file"
        id={name}
        name={name}
        ref={register}
        multiple={multiple}
      />
      <div className={styles['file__error-container']}>
        <span className={styles['file__error-message']}>{ error?.message }</span>
      </div>
    </div>
  );
};

File.propTypes = {
  multiple: PropTypes.bool
};

export default File;
