import React from 'react';
import PropTypes from 'prop-types';
import styles from './image.module.scss';

const Image = ({ src, alt, maxWidth }) => {
  return (
    <div className={styles['image']} style={{ maxWidth }}>
      <img src={src} alt={alt} className={styles['image__image']}/>
    </div>
  );
};

Image.propTypes = {
  src: PropTypes.object,
  alt: PropTypes.string,
  maxWidth: PropTypes.number
};

export default Image;
