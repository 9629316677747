// Dirty
import React from 'react';
import TileCheckbox from './tileCheckbox';
import { values as valuesGeneral } from '../../../data/general';
import styles from './tileCheckboxGroup.module.scss';

const TileCheckboxGroup = ({ name, values, disabledValues, register, error }) => {
  return (
    <div className={styles['checkbox-group']}>
      <div className={styles['checkbox-group__items']}>
        {values.map(value => (
          <div key={value.id} className={styles['checkbox-group__item']}>
            <TileCheckbox
              name={name}
              id={value.id}
              label={value.name}
              icon={value.icon}
              register={register}
              hasError={error}
              isHighlighted={value.id === valuesGeneral.generalIntend.UNKNOWN}
              disabled={disabledValues?.includes(value.id)}/>
          </div>
        ))}
      </div>
      <div className={styles['checkbox-group__error-container']}>
        <span className={styles['checkbox-group__error-message']}>{error?.message}</span>
      </div>
    </div>
  );
};

export default TileCheckboxGroup;
