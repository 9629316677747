import React from 'react';
import PropTypes from 'prop-types';
import styles from './text.module.scss';

const Text = ({ name, unit, exampleText, placeholder, label, register, error }) => {
  return (
    <div className={styles['text']}>
      {label && (
        <label className={styles['text__label']} htmlFor={name}>{ label }</label>
      )}
      <div className={styles['text__input-container']}>
        <input
          name={name}
          className={`${error ? 'has-error' : ''} ${styles['text__input'] || ''}`}
          type="text"
          ref={register}
          placeholder={placeholder}
        />
        {unit && (
          <span className={styles['text__unit']} dangerouslySetInnerHTML={{ __html: unit }} />
        )}
        {exampleText && (
          <span className={styles['text__example-text']}>{ exampleText }</span>
        )}
      </div>
      <div className={styles['text__error-container']}>
        <span className={styles['text__error-message']}>{ error?.message }</span>
      </div>
    </div>
  );
};

Text.propTypes = {
  name: PropTypes.string,
  unit: PropTypes.string,
  exampleText: PropTypes.string,
  placeholder: PropTypes.string,
  register: PropTypes.func,
  error: PropTypes.object
};

export default Text;
