import React from 'react';
import styles from './textarea.module.scss';

const Textarea = ({ name, placeholder, label, register, error }) => {
  return (
    <div className={styles['textarea']}>
      <label className={styles['textarea__label']}>{label}</label>
      <textarea
        className={`${error ? 'has-error' : ''} ${styles['textarea__textarea'] || ''}`}
        id={name}
        name={name}
        ref={register}
        placeholder={placeholder}
        // rows="7"
        // cols="45"
      />
      <div className={styles['textarea__error-container']}>
        <span className={styles['textarea__error-message']}>{ error?.message }</span>
      </div>
    </div>
  );
};

export default Textarea;
