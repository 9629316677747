import React from 'react';
import PropTypes from 'prop-types';
import { inputType } from './constants';
import Text from './inputs/text';
import RadioGroup from './inputs/radioGroup';
import CheckboxGroup from './inputs/checkboxGroup';
import Textarea from './inputs/textarea';
import File from './inputs/file';

const InputSelector = ({ generatorStep, register, errors }) => {
  // eslint-disable-next-line default-case
  switch (generatorStep.type) {
    case inputType.TEXT:
      return (
        <Text
          name={generatorStep.id}
          unit={generatorStep.unit}
          placeholder={generatorStep.placeholder}
          label={generatorStep.label}
          exampleText={generatorStep.exampleText}
          register={register(generatorStep.validators)}
          error={errors[generatorStep.id]}
        />
      )
    case inputType.RADIO:
      return (
        <RadioGroup
          name={generatorStep.id}
          label={generatorStep.label}
          values={generatorStep.values}
          register={register(generatorStep.validators)}
          error={errors[generatorStep.id]}
        />
      )
    case inputType.CHECKBOX:
      return (
        <CheckboxGroup
          name={generatorStep.id}
          label={generatorStep.label}
          values={generatorStep.values}
          register={register(generatorStep.validators)}
          error={errors[generatorStep.id]}
        />
      )
    case inputType.TEXTAREA:
      return (
        <Textarea
          name={generatorStep.id}
          placeholder={generatorStep.placeholder}
          label={generatorStep.label}
          register={register(generatorStep.validators)}
          error={errors[generatorStep.id]}
        />
      )
    case inputType.FILES:
      return (
        <File
          multiple={true}
          name={generatorStep.id}
          label={generatorStep.label}
          register={register(generatorStep.validators)}
          error={errors[generatorStep.id]}
          accept={generatorStep.acceptFiletypes}
        />
      )
  }

  return (
    <div>

    </div>
  );
};

InputSelector.propTypes = {
  generatorStep: PropTypes.object
};

export default InputSelector;
