import React from 'react';
import PropTypes from 'prop-types';
import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import rotatedImage0 from '../../../images/icon_fullcompass_0.png';
import rotatedImage45 from '../../../images/icon_fullcompass_45.png';
import rotatedImage90 from '../../../images/icon_fullcompass_90.png';
import rotatedImage135 from '../../../images/icon_fullcompass_135.png';
import rotatedImage180 from '../../../images/icon_fullcompass_180.png';
import rotatedImage225 from '../../../images/icon_fullcompass_225.png';
import rotatedImage270 from '../../../images/icon_fullcompass_270.png';
import rotatedImage315 from '../../../images/icon_fullcompass_315.png';

const styles = StyleSheet.create({
  container: {
    width: 100,
    height: 100,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  // backgroundImage: {
  //   backgroundImage: `url(${backgroundImage})`,
  //   backgroundRepeat: 'no-repeat',
  //   backgroundPosition: 'center',
  //   backgroundSize: 'contain',
  //   position: 'relative',
  //   width: '100%',
  //   height: '100%'
  // },
  image: {
    objectFit: 'contain'
  },
  text: {
    marginLeft: '10'
  }
});

const RotatedImage = ({ degrees }) => {
  const getImage = () => {
    // eslint-disable-next-line default-case
    switch (degrees) {
      case 0:
      case 360:
        return rotatedImage0;
      case 45:
        return rotatedImage45;
      case 90:
        return rotatedImage90;
      case 135:
        return rotatedImage135;
      case 180:
        return rotatedImage180;
      case 225:
        return rotatedImage225;
      case 270:
        return rotatedImage270;
      case 315:
        return rotatedImage315;
    }
  }

  const getCardinalDirection = () => {
    // eslint-disable-next-line default-case
    switch (degrees) {
      case 0:
      case 360:
        return 'N';
      case 45:
        return 'NO';
      case 90:
        return 'O';
      case 135:
        return 'SO';
      case 180:
        return 'S';
      case 225:
        return 'SW';
      case 270:
        return 'W';
      case 315:
        return 'NW';
    }
  }

  return (
    <View style={styles.container}>
      {/*<View style={styles.backgroundImage}>*/}
        <Image style={styles.image} src={getImage()} />
        <Text style={styles.text}>{getCardinalDirection()}</Text>
      {/*</View>*/}
    </View>
  );
};

RotatedImage.propTypes = {
  degrees: PropTypes.oneOf([0, 45, 90, 135, 180, 225, 270, 315, 360])
};

export default RotatedImage;
