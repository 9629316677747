import React from 'react';
import PropTypes from 'prop-types';
import styles from './radioGroup.module.scss';

const RadioGroup = ({ name, label, values, register, error }) => {
  return (
    <div className={styles['radio']}>
      {label && (
        <span className={styles['radio__group-label']}>{label}</span>
      )}
      <div className={styles['radio__buttons']}>
        {values.map(value => (
          <label key={value.id} className={styles['radio__input-group']}>
            <span className={`${error ? 'has-error' : ''} ${styles['radio__input-wrap'] || ''} ${value.image ? styles['radio__input-wrap_has-image'] : ''}`}>
              <input
                type="radio"
                className={styles['radio__input']}
                name={name}
                id={value.id}
                value={value.id}
                ref={register}
              />
              <span className={`${styles['radio__control']} ${error ? styles['radio__control_has-error'] : ''}`} />
              {value.image && (
                <div className={styles['radio__image-container']}>
                  <img className={styles['radio__image']} src={value.image.src} alt={value.image.alt} />
                </div>
              )}
              <span className={styles['radio__label']}>{value.name}</span>
            </span>
          </label>
        ))}
      </div>
      <div className={styles['radio__error-container']}>
        <span className={styles['radio__error-message']}>{ error?.message }</span>
      </div>
    </div>
  );
};

RadioGroup.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  })),
  register: PropTypes.func,
  error: PropTypes.object
};

export default RadioGroup;
