import val from '../helper/validator';
import { inputType } from '../components/form/constants';

export const values = {
  generalIntend: {
    UNKNOWN: 'unknown',
    GAS: 'gas',
    AIR_HEAT_PUMP: 'airHeatPump',
    GEOTHERMAL_HEAT_PUMP: 'geothermalHeatPump',
    SOLARTHERMICS: 'solarthermics',
    PHOTOVOLTAICS: 'photovoltaics',
    AIR_CONDITIONER: 'airConditioner'
  }
}

// Ist eigentlich 'intend' und nicht 'general'
export const general = {
  generalIntend: {
    id: 'generalIntend',
    pdfLabel: 'Für welche Heizungen interessieren Sie sich?',
    values: [
      {
        id: values.generalIntend.UNKNOWN,
        name: 'Weiß ich noch nicht',
        icon: require('../images/icon_dontknow.svg').default
      },
      {
        id: values.generalIntend.GAS,
        name: 'Gas',
        icon: require('../images/icon_gas.svg').default
      },
      {
        id: values.generalIntend.AIR_HEAT_PUMP,
        name: 'Luftwärmepumpe',
        icon: require('../images/icon_luftwaermepumpe.svg').default
      },
      {
        id: values.generalIntend.GEOTHERMAL_HEAT_PUMP,
        name: 'Erdwärmepumpe',
        icon: require('../images/icon_erdwaermepumpe.svg').default
      },
      {
        id: values.generalIntend.SOLARTHERMICS,
        name: 'Solarthermie',
        icon: require('../images/icon_solarthermie.svg').default
      },
      {
        id: values.generalIntend.PHOTOVOLTAICS,
        name: 'Photovoltaik',
        icon: require('../images/icon_photovoltaik.svg').default
      },
      {
        id: values.generalIntend.AIR_CONDITIONER,
        name: 'Klimagerät',
        icon: require('../images/icon_klima.svg').default
      },
    ],
    validators: val.setValidators([val.isRequired()])
  }
}

export const contact = {
  contactCompanyPrivate: {
    id: 'contactCompanyPrivate',
    pdfLabel: 'Firma oder Privat',
    values: [
      { id: 'firma', name: 'Firma' },
      { id: 'privat', name: 'Privat' },
    ],
    type: inputType.RADIO,
    validators: val.setValidators([val.isRequired()])
  },
  contactFirstName: {
    id: 'contactFirstName',
    type: inputType.TEXT,
    initialValue: '',
    label: 'Vorname',
    pdfLabel: 'Vorname',
    placeholder: 'Vorname',
    // exampleText: 'zB. 12',
    validators: val.setValidators([val.isRequired()])
  },
  contactLastName: {
    id: 'contactLastName',
    type: inputType.TEXT,
    initialValue: '',
    label: 'Nachname',
    pdfLabel: 'Nachname',
    placeholder: 'Nachname',
    // exampleText: 'zB. 12',
    validators: val.setValidators([val.isRequired()])
  },
  contactPostcode: {
    id: 'contactPostcode',
    type: inputType.TEXT,
    initialValue: '',
    label: 'PLZ',
    pdfLabel: 'PLZ',
    placeholder: 'PLZ',
    // exampleText: 'zB. 12',
    validators: val.setValidators([val.isRequired(), val.isPLZ()])
  },
  contactCity: {
    id: 'contactCity',
    type: inputType.TEXT,
    initialValue: '',
    label: 'Ort',
    pdfLabel: 'Ort',
    placeholder: 'Ort',
    // exampleText: 'zB. 12',
    validators: val.setValidators([val.isRequired()])
  },
  contactStreet: {
    id: 'contactStreet',
    type: inputType.TEXT,
    initialValue: '',
    label: 'Straße + Hausnummer',
    pdfLabel: 'Straße + Hausnummer',
    placeholder: 'Straße + Hausnummer',
    // exampleText: 'zB. 12',
    validators: val.setValidators([val.isRequired()])
  },
  contactEmail: {
    id: 'contactEmail',
    type: inputType.TEXT,
    initialValue: '',
    label: 'E-Mail',
    pdfLabel: 'E-Mail',
    placeholder: 'E-Mail',
    // exampleText: 'zB. 12',
    validators: val.setValidators([val.isRequired(), val.isEmail()])
  },
  contactPhone: {
    id: 'contactPhone',
    type: inputType.TEXT,
    initialValue: '',
    label: 'Telefonnummer',
    pdfLabel: 'Telefonnummer',
    placeholder: 'Telefonnummer',
    // exampleText: 'zB. 12',
    validators: val.setValidators([val.isRequired(), val.isNumber()])
  },
  contactMessage: {
    id: 'contactMessage',
    type: inputType.TEXTAREA,
    initialValue: '',
    label: 'Nachricht',
    pdfLabel: 'Nachricht',
    placeholder: 'Nachricht',
    // exampleText: 'zB. 12',
    validators: val.setValidators([val.isRequired()])
  },
  contactFiles: {
    id: 'contactFiles',
    type: inputType.FILES,
    label: 'Datei hochladen (zB. Grundrisse, Bilder, Heizlastberechnung, ...)',
    pdfLabel: 'Datei hochladen (zB. Grundrisse, Bilder, Heizlastberechnung, ...)',
    isOptional: true,
    acceptFiletypes: [
      val.fileTypes.PNG,
      val.fileTypes.JPEG,
      val.fileTypes.PDF,
      val.fileTypes.PLAIN,
      ...val.fileTypes.DWG,
      ...val.fileTypes.DXF
    ],
    validators: val.setValidators([val.hasPermittedFileValues(
      [
        val.fileTypes.PNG,
        val.fileTypes.JPEG,
        val.fileTypes.PDF,
        val.fileTypes.PLAIN,
        ...val.fileTypes.DWG,
        ...val.fileTypes.DXF
      ],
      (10 * 1000 * 1000))]) //10MB
  },
}
