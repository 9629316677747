import React from 'react';
import styles from './imageRotations.module.scss';
import Button from '../../ui/button';
import { ReactComponent as IconRotateLeft } from '../../../images/rotate_left-white-18dp.svg'
import { ReactComponent as IconRotateRight } from '../../../images/rotate_right-white-18dp.svg'

const ImageRotation = ({ src, alt, backgroundImage, degrees, onChange, onFocus }) => {
  const incrementDegrees = () => {
    onChange((degrees + 45) % 360);
  }

  const decrementDegrees = () => {
    if (degrees === 0) degrees = 360;
    onChange(degrees - 45);
  }

  return (
    <div className={styles['image-rotation']}>
      <div className={styles['image-rotation__left-btn-container']}>
        <Button
          size="sm"
          hasIcon={true}
          color="primary"
          className={styles['image-rotation__left-btn']}
          type="button"
          onClick={decrementDegrees}
          onFocus={onFocus}
        >
          <IconRotateLeft />
        </Button>
      </div>
      <div className={styles['image-rotation__image-container']}>
        <div className={styles['image-rotation__background-image']} style={{backgroundImage: `url(${backgroundImage})`}}>
          <img className={styles['image-rotation__image']} src={src} alt={alt} style={{transform: `translate(-50%, -50%) rotate(${degrees}deg)`}}/>
        </div>
      </div>
      <div className={styles['image-rotation__right-btn-container']}>
        <Button
          size="sm"
          color="primary"
          className={styles['image-rotation__right-btn']}
          hasIcon={true}
          type="button"
          onClick={incrementDegrees}
          onFocus={onFocus}
        >
          <IconRotateRight />
        </Button>
      </div>
    </div>
  );
};

export default ImageRotation;
