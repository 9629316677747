import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { scale, fontSizes, colors } from '../styles';

const styles = StyleSheet.create({
  keyValueBlock: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: fontSizes.NORMAL
  },
  keyValueColumn: {
    width: '48%',
    borderBottomWidth: '1',
    borderBottomStyle: 'solid',
    borderBottomColor: colors.LIGHT_2,
    // paddingLeft: 10,
    paddingTop: scale.SM_1,
    paddingBottom: scale.SM_2,
  },
  valueColumn: {
    fontWeight: 500
  }
})



const Row = ({ question, value }) => {
  return (
    <View style={styles.keyValueBlock}>
      <View style={styles.keyValueColumn}>
        <Text>{question}</Text>
      </View>
      <View style={[styles.keyValueColumn, styles.valueColumn]}>
        {value}
      </View>
    </View>
  );
};

Row.propTypes = {
  key: PropTypes.string,
  value: PropTypes.node
};

export default Row;
