import React from 'react';
import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import logo from '../../../images/logo_pdf.png';
import { colors, fontSizes } from '../styles';
import mdHeizsysteme from '../../../config/md-heizsysteme';

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  logoContainer: {
    width: 100,
    height: 100
  },
  logo: {
    // width: '100%',
    // height: '100%',
    objectFit: 'contain'
  },
  headerContact: {
    flexDirection: 'row',
    color: colors.LIGHT_1,
    fontSize: fontSizes.SM_1,
    marginLeft: 'auto'
  }
})

const Header = props => {
  return (
    <View style={styles.header}>
      <View style={styles.logoContainer}>
        <Image style={styles.logo} src={logo} />
      </View>
      <View style={styles.headerContact}>
        <Text>{mdHeizsysteme.address.street} - {mdHeizsysteme.address.postcode} {mdHeizsysteme.address.city}&nbsp;&nbsp;|&nbsp;&nbsp;</Text>
        <Text>{mdHeizsysteme.phone.text}&nbsp;&nbsp;|&nbsp;&nbsp;</Text>
        <Text>{mdHeizsysteme.email}</Text>
      </View>
    </View>
  );
};

export default Header;
